<template>
    <span class="px3 py2" :class="get_status_color(student.attend_status)" >
        {{get_status_label(student.attend_status)}}
    </span>
</template>

<script>
export default {
    props : {
        student : Object,
    },
    data : function() {
        return {
        }
    },
    computed : {
    },
    methods : {
        /* status */
        get_status_label: function (status) {
            let label_dict = {
                'attend': '출',
                'absent': '결',
                'late': '지',
                'online': '온',
            };
            if (this.$c.hasProperty(label_dict,status) == true) {
                return label_dict[status];
            }
            return '대';
        },
        get_status_color: function (status) {
            let color_dict = {
                'attend': 'bg-positive text-white',
                'absent': 'bg-negative text-white',
                'late': 'bg-warning text-white',
                'online': 'bg-purple text-white',
            };
            if (this.$c.hasProperty(color_dict,status) == true) {
                return color_dict[status];
            }
            return 'bg-grey text-white';
        },
    },
    beforeMount : function() {
    },
    mounted : function() {
    },
    beforeUnmount : function() {
    },
}
</script>
