<template>
<div>
    <q-dialog persistent="" v-model="is_open">
        <q-card class="q-px-sm q-pb-sm" style="width:600px; max-width:80vw;">
            <q-card-section>
                <div class="text-center text-h6">
                    보강설정
                </div>
            </q-card-section>
            <q-card-section class="q-py-none">
                <com-data-form :form="form" ref="data_form">
                    <template v-slot="props">
                        <div class="row">
                            <label class="w80px q-pt-sm">보강상태</label>
                            <div class="col">
                                <q-field borderless no-error-icon
                                        :error="(props.form_error.makeup_status)?true:false" 
                                        :error-message="props.form_error.makeup_status" >
                                    <q-radio :label="status.label" :val="status.makeup_class_status" 
                                              v-model="form.makeup_status"
                                              v-for="status in makeup_class_status_list" 
                                              :key="status.makeup_class_status" />
                                </q-field>
                                          
                            </div>
                        </div>
                        
                        <div class="row">
                            <label class="w80px q-pt-sm">보강일</label>
                            <div class="col">
                                <div class="row">
                                    <q-input class="w140px" outlined no-error-icon
                                            :error="(props.form_error.class_date)?true:false" :error-message="props.form_error.class_date" 
                                            v-model="form.class_date" >
                                        <template v-slot:append>
                                            <q-icon name="event" class="cursor-pointer">
                                                <q-popup-proxy transition-show="scale" transition-hide="scale" ref="popup_class_date" >
                                                    <q-date v-model="form.class_date" :mask="date_format" @update:model-value="()=>$refs.popup_class_date.hide()" >
                                                        <div class="row items-center justify-end">
                                                            <q-btn unelevated v-close-popup label="Close" color="primary" flat />
                                                        </div>
                                                    </q-date>
                                                </q-popup-proxy>
                                            </q-icon>
                                        </template>
                                    </q-input>
                                    <div class="q-pt-sm q-pl-md q-pr-sm"> 시간 : </div>
                                    
                                    <q-input class="w100px" outlined no-error-icon
                                            :error="(props.form_error.start_time)?true:false" :error-message="props.form_error.start_time" 
                                            v-model="form.start_time" >
                                        <template v-slot:append>
                                            <q-icon name="access_time" class="cursor-pointer">
                                                <q-popup-proxy transition-show="scale" transition-hide="scale" ref="popup_start_time" >
                                                    <q-time v-model="form.start_time" :mask="time_format" format24h
                                                            @update:model-value="()=>$refs.popup_start_time.hide()" >
                                                        <div class="row items-center justify-end">
                                                            <q-btn unelevated v-close-popup label="Close" color="primary" flat />
                                                        </div>
                                                    </q-time>
                                                </q-popup-proxy>
                                            </q-icon>
                                        </template>
                                    </q-input>
                                    <span class="q-pt-sm q-px-sm"> ~ </span>
                                    <q-input class="w100px" outlined no-error-icon
                                            :error="(props.form_error.end_time)?true:false" :error-message="props.form_error.end_time" 
                                            v-model="form.end_time" >
                                        <template v-slot:append>
                                            <q-icon name="access_time" class="cursor-pointer">
                                                <q-popup-proxy transition-show="scale" transition-hide="scale" ref="popup_end_time" >
                                                    <q-time v-model="form.end_time" :mask="time_format" format24h
                                                            @update:model-value="()=>$refs.popup_end_time.hide()" >
                                                        <div class="row items-center justify-end">
                                                            <q-btn unelevated v-close-popup label="Close" color="primary" flat />
                                                        </div>
                                                    </q-time>
                                                </q-popup-proxy>
                                            </q-icon>
                                        </template>
                                    </q-input>
                                    
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <label class="w80px q-pt-sm">보강선생님</label>
                            <div class="col">
                                <q-select outlined  :options="filter_teacher_list" style="width:200px"
                                          :value="makeup_teacher_name"
                                          use-input 
                                          @filter="filterFn_teacher"
                                          @input-value="set_teacher"
                                          v-model="makeup_teacher"
                                          @update:model-value="select_teacher"
                                         :error="(props.form_error.admin_id)?true:false" :error-message="props.form_error.admin_id" >
                                </q-select>
                                          
                            </div>
                        </div>
                        
                        <div class="row">
                            <label class="w80px q-pt-sm">보강메모</label>
                            <div class="col">
                                <q-input type="textarea" outlined
                                        :error="(props.form_error.memo)?true:false" :error-message="props.form_error.memo"
                                         v-model="form.memo" ></q-input>
                            </div>
                        </div>
                    </template>
                </com-data-form>
            </q-card-section>
            <q-card-actions align="center" class="q-px-md">
                <q-btn color="grey" label="닫기" outline v-close-popup></q-btn>
                <q-btn color="negative" label="보강삭제" @click="delete_makeup_class" 
                       v-if="student_makeup_class_id>0" ></q-btn>
                <q-btn color="primary" label="보강저장" @click="save_makeup_class" ></q-btn>
            </q-card-actions>
        </q-card>
    </q-dialog>
</div>
</template>


<script>
import comDataForm from '@/stories/comDataForm/comDataForm.vue';

export default {
    components : {
        comDataForm,
    },
    data : function() {
        return {
            is_open : false,
            callback : null,
            class_inst_id : 0,
            user_id : 0,
            student_makeup_class_id : 0,
            makeup_teacher_name : '',
            makeup_teacher : null,
            filter_teacher_list : [],
            form : {
                class_inst_id : 0,
                class_id : 0,
                class_course_id : 0,
                class_date : '',
                start_time : '',
                end_time : '',
                makeup_status : '',
                memo : '',
                admin_id :0,
            },
        };
    },
    computed : {
        admin () {
            return this.$store.state.admin;
        },
        
        date_format : function() {
            return this.$store.state.date_format;
        },
        time_format : function() {
            return this.$store.state.time_format;
        },
        makeup_class_status_list() {
            return this.$store.state.makeup_class_status_list;
        },
        teacher_list() {
            return this.$store.state.teacher_list;
        },
    },
    methods : {
        /* open */
        open : function(student_attend, callback) {
            let vm = this;
            this.is_open = true;
            this.callback = callback;
            this.class_inst_id = student_attend.class_inst_id;
            this.user_id = student_attend.user_id;
            this.set_form(student_attend);
            this.check_teacher();
            this.load_makeup_class();
            this.$nextTick(function() {
                vm.$refs.data_form.clear_error();
            });
        },
        set_form(student_attend) {
            this.form.class_inst_id = student_attend.class_inst_id;
            this.form.class_id = student_attend.class_id;
            this.form.class_course_id = student_attend.class_course_id;
            this.form.class_date = '';
            this.form.start_time = '';
            this.form.end_time = '';
            this.form.makeup_status = '';
            this.form.memo = '';
            this.form.admin_id = this.admin.admin_id;
            this.student_makeup_class_id = 0;
            this.makeup_teacher = null;
        },
        check_teacher() {
            this.teacher_list.forEach(x => {
                if(x.admin_id == this.form.admin_id) {
                    this.makeup_teacher = x;
                }
            });
        },
        
        /* select teacher */
        select_teacher : function() {
            if(this.makeup_teacher) {
                this.form.admin_id = this.makeup_teacher.admin_id;
            }
        },
        set_teacher(value){
            this.makeup_teacher_name = value;
        },
        filterFn_teacher(val, update, /*abort*/) {
            // 1글자이상 입력시
            //if (val.length < 1) { abort(); return; }
            update(() => {
                const keyword = val.toLowerCase();
                this.filter_teacher_list = this.teacher_list.filter((v) => v.label.toLowerCase().indexOf(keyword) > -1);
            });
        },
        
        
        /* load */
        load_makeup_class() {
            let vm = this;
            let url = `/api/admin/app/aca_erp/students/${this.user_id}/classes/class_insts/${this.class_inst_id}/makeup_class`;
            this.$c.io.get(url, this.form).then(function(response){
                let data = response.data;
                if(data.success) {
                    if(data.makeup_class) {
                        for(let key in vm.form) {
                            vm.form[key] = data.makeup_class[key];
                        }
                        vm.student_makeup_class_id = data.makeup_class.student_makeup_class_id;
                        vm.check_teacher();
                    }
                }
            });
        },
        
        /* handle */
        save_makeup_class() {
            let vm = this;
            console.log('save');
            console.log(this.form);
            let url = `/api/admin/app/aca_erp/students/${this.user_id}/classes/makeup_class/${this.student_makeup_class_id}`;
            this.$refs.data_form.put(url, this.form).then(function(data){
                if(data.success) {
                    vm.is_open = false;
                    if( vm.callback ) { vm.callback(); }
                    vm.$c.response_notify(1, "보강정보를 저장하였습니다.");
                }
            });
        },
        delete_makeup_class() {
            let vm = this;
            this.$q.dialog({ title: '<div class="text-center">확인</div>', 
                message: '<div class="text-center">삭제할까요?</div>', cancel: true, html: true
            }).onOk(() => {
                let url = `/api/admin/app/aca_erp/students/${this.user_id}/classes/makeup_class/${this.student_makeup_class_id}`;
                vm.$refs.data_form.delete(url, {}).then(function(data) {
                    if(data.success) {
                        vm.is_open = false;
                        if(vm.callback) { vm.callback(); }
                        vm.$c.response_notify(1, "보강정보를 삭제하였습니다.");
                    }
                });
            });
        },
    },
    
    beforeMount : function() {
    },
};
</script>