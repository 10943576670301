<template>
<div>
    {{get_school_level()}}
</div>
</template>


<script>
export default {
    props : {
        row : {
            type: Object,
            required: true
        },
    },
    data: function () {
        return {
            school_level : {
                'primary' : '초',
                'middle'  : '중',
                'high'    : '고',
            }
        };
    },
    methods : {
        get_school_level() {
            if(this.$c.hasProperty(this.school_level, this.row.school_level)) {
                return this.school_level[this.row.school_level];
            }
            return '';
        },
    }, 
    beforeMount : function() {
    },
}
</script>
