import { createStore } from "vuex";

const store = createStore({
	state: {
		user_id : 0,
		user : {
			user_id:0,
			user_group_id: 0,
			u_name: "",
			user_name:"",
		},
		admin_id : 0,
		admin : {
			admin_id:0,
			admin_group_id: 0,
			a_name: "",
			admin_name:"",
		},
		language_id : "64",
		currency_id : "129",
		
		datetime_format : "YYYY-MM-DD HH:mm",
		date_format : "YYYY-MM-DD",
		time_format : "HH:mm",
		
		currency_value : "1.00000000",
		currency_symbol_left : "",
		currency_symbol_right : "원",
		decimal_place : "0",
		
		language_list : [],
		currency_list : [],
		i18n : {},
		permission : {},
		
		/* loading관리 */
		loading : {
			is_loading : false,
			load_data_dict : {},
		},
	},
	actions : {
		loading(context, data) {
			context.state.loading.is_loading = false;
			context.state.loading.load_data_dict[data.name] = data.loading;
			for(let key in context.state.loading.load_data_dict) {
				if(context.state.loading.load_data_dict[key]==true) {
					context.state.loading.is_loading = true;
				}
			}
		},
	},
});

export default store;
