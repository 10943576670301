const mission = {
    mission_type_color : {
        'class' : 'blue', 
        'homework' : 'deep-orange', 
        'prepare' : 'grey',
    },
    target_type_color : {
        'class_teacher':'blue', 
        'teacher':'teal', 
        'student':'green',
    },

    make_mission(student) {
        student.title = '';
        //반미션
        try {
            student.class_mission_list = JSON.parse(student.class_mission);
            if(student.class_mission_list.length>0) {
                student.title = student.class_mission_list[0]['description'];
            }
        } catch(e) {
            student.class_mission_list = [];
        }
        //반미션 결과
        try {
            student.class_mission_result_list = JSON.parse(student.class_mission_result);
        } catch(e) {
            student.class_mission_result_list = [];
        }
        //반미션과 결과데이터 체크
        for(let i=0; i<student.class_mission_list.length; i++) {
            let cm = student.class_mission_list[i];
            if(i < student.class_mission_result_list.length) {
                let cmr = student.class_mission_result_list[i];
                cm.result= {
                    is_check : cmr.is_check,
                    check_date : cmr.check_date,
                    is_pass : cmr.is_pass,
                    feedback : cmr.feedback,
                };
            } else { //결과기록이 없으면 초기화
                cm.result= {
                    is_check : 0,
                    check_date : '',
                    is_pass : 0,
                    feedback : '',
                };
            }
        }

        //학생개별미션
        try {
            student.mission_list = JSON.parse(student.mission);
            if(student.mission_list.length>0) {
                student.title = student.mission_list[0]['description'];
            }
        } catch(e) {
            student.mission_list = [];
        }
        //학생개별미션 결과
        try {
            student.student_mission_result_list = JSON.parse(student.student_mission_result);
        } catch(e) {
            student.student_mission_result_list = [];
        }
        //학생개별미션과 결과데이터 체크
        for(let i=0; i<student.mission_list.length; i++) {
            let sm = student.mission_list[i];
            if(i < student.student_mission_result_list.length) {
                let smr = student.student_mission_result_list[i];
                sm.result= {
                    is_check : smr.is_check,
                    check_date : smr.check_date,
                    is_pass : smr.is_pass,
                    feedback : smr.feedback,
                };
            } else { //결과기록이 없으면 초기화
                sm.result= {
                    is_check : 0,
                    check_date : '',
                    is_pass : 0,
                    feedback : '',
                };
            }
        }

        //진행율 계산
        let total_mission = student.class_mission_list.length+student.mission_list.length;
        let pass_count = 0;
        student.class_mission_list.forEach(x => {
            if(x.result.is_check) { pass_count+=1; }
        });
        student.mission_list.forEach(x => {
            if(x.result.is_check) { pass_count+=1; }
        });
        student.progress_rate = 0;
        if(total_mission>0) {
            student.progress_rate = parseInt(pass_count * 100 / total_mission);
        }
    },
};

export default mission;