<template>
<div class="ref_class_type">
    <q-table :rows="class_type_list" :columns="class_type_columns" separator="cell" flat bordered dense
             :rows-per-page-options="[0]" row-key="class_type_id" selection="single" hide-bottom
             table-header-class="bg-blue-1"
             class="sticky-header-table" style="height:300px" 
             :key="key_class_type"
             v-model:selected="selected" @update:selected="change_selection" >
        <template v-slot:body="props">
            <tr class="pointer"
                :class="{active:props.selected}" @click="click_select(props)">
                <td>
                    <q-checkbox dense v-model="props.selected" />
                </td>
                <td class="v-middle"> 
                    <class_type_renderer :key="props.row.class_type_id" :row="props.row" ></class_type_renderer>
                </td>
            </tr>
        </template>
    </q-table>
    
</div>
</template>


<script>
import class_type_renderer from '@_shared/class/class_type_renderer.vue';

export default {
    props : {
        form : Object,
    },
    components : {
        class_type_renderer,
    },
    data : function() {
        return {
            key_class_type : 1,
            origin_class_type_list : [],
            class_type_list : [],
            class_type_columns: [
                { name:'class_type', align: 'left', label: '반형태', field: 'class_type' },
            ],
            pagination : {
                page: 1,
                per_page : 20,
                total_pages: 0,
                total_count: 0
            },
            selected : [],
        }
    },
    methods : {
        
        /* change */
        clear_selection() {
            let vm = this;
            vm.selected = [];
            this.$emit('change_select_all', vm.selected.length==0);
            this.$emitter.emit("change_class_type", vm.selected);
        },
        
        change_selection : function() {
            let vm = this;
            this.$emit('change_select_all', vm.selected.length==0);
            this.$emitter.emit("change_class_type", vm.selected);
        },
        
        click_select(props) {
            if(props.selected==false) {
                props.selected=true;
            }
        },
        
        
        /* load */
        load_ref_class_type : function() {
            let vm = this;
            let filter = {};
            if(!vm.form.include_close_ref_class) {
                filter['is_alive'] = {"filterType": "number","type": "=","filter": 1};
            }
            let form_data = {
                page : 1,
                limit : 0,
                filter : JSON.stringify(filter),
                sort : '[]',
                tzoffset : this.$c.tzoffset,
            };
            vm.$store.state.class_type_list = [];
            this.$c.io.get('/api/admin/app/aca_erp/classes/class_types', form_data).then( function(response) {
                var data = response.data;
                if(data['success']) {
                    /*for(let i=0; i<data['class_type_list'].length; i++) {
                        var p = data['class_type_list'][i];
                    }*/
                    vm.class_type_list = [];
                    vm.origin_class_type_list = data['class_type_list'];
                    vm.origin_class_type_list.forEach(x =>{
                        x.label = x.class_type;
                        x.value = x.class_type_id;
                        if(!vm.form.include_close_ref_class) {
                            if(x.is_alive==0) { return; }
                        }
                        vm.class_type_list.push(x);
                    });
                    //살아 있는 것만 세팅함.
                    vm.$store.state.class_type_list = vm.class_type_list;
                    
                    vm.pagination.page = 1;
                    vm.pagination.total_count = vm.class_type_list.length;
                    vm.pagination.total_pages = 1;
                    //테이블 화면 갱신 + 반리스트 갱신
                    vm.key_class_type += 1;
                    vm.change_selection();
                } 
            });
        },
    },
    beforeMount : function() {
        this.load_ref_class_type();
    },
    mounted : function() {
    },
}
</script>

<style>
.ref_class_type tr.active td {
    background-color:#1a237e;
    color:white;
}
</style>
