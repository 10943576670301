<template>
<div :class="{'text-red-4':is_alive==0}" :class_id="this.row.class_id">
    <span :class_id="this.row.class_id" >{{class_name}}</span>
    <q-icon name="close" v-if="is_alive==0" />
</div>
</template>


<script>
export default {
    props : {
        row : {
            type: Object,
            required: true
        },
    },
    data: function () {
        return {
            class_name : '',
            is_alive : 1,
        };
    },
    methods : {
    }, 
    beforeMount : function() {
        this.class_name = this.row.class_name;
        this.is_alive = this.row.is_alive;
    },
}
</script>
