<template>
<div>
    <q-dialog persistent="" v-model="is_open">
        <q-card class="q-px-sm q-pb-sm" >
            <q-card-section>
                <div class="text-center">메시지 {{dialog_title}}</div>
            </q-card-section>
            <q-card-section class="q-py-none">
                <!--
                <div class="border-b1 q-px-sm pb2 q-mb-md" v-if="this.msg_company=='my_phone'">
                    메시지 발송회사의 옵션 :<br/>
                    '내폰에서 발송'임<br/>
                    <span class="text-red">앱에서 발송버튼을 클릭해야함</span>
                </div> -->
                <table>
                <tbody>
                <tr>
                    <td> 발송 대상학생 수 : </td>
                    <td class="text-right w80px"> {{total_cnt}}명 </td>
                </tr>
                <tr>
                    <td> 발송된 학생 수 : </td>
                    <td class="text-right"> {{sent_cnt}}명 </td>
                </tr>
                </tbody>
                </table>
            </q-card-section>
            <q-card-actions align="center" class="q-mt-md q-pt-md q-px-md border-t1" style="height:64px">
                <q-btn color="primary" label="닫기" outline v-close-popup v-if="is_complete==1"></q-btn>
            </q-card-actions>
        </q-card>
    </q-dialog>
</div>
</template>


<script>
export default {
    data : function() {
        return {
            is_open : false,
            callback : null,
            message_code : '',
            is_complete : 0,
            dialog_title : '',
            interval : 0,
            total_cnt : 0,
            sent_cnt : 0,
        };
    },
    computed: {
        center_api() {
            return this.$store.state.center_api;
        },
        msg_company() {
            return this.$store.state.center_api.msg_company;
        },
    },
    methods : {
        /* open */
        open : function(message_code, callback) {
            this.callback = callback;
            this.is_open = true;
            this.message_code = message_code;
            this.is_complete = 0;
            this.interval = 1000;
            this.dialog_title = '발송중 ...';
            this.load_check();
        },
        
        /* load */
        load_check() {
            let vm = this;
            let source = new EventSource("/api/admin/app/aca_erp/util/message/sse_check?message_code="+vm.message_code);
            source.addEventListener("update", function(event) {
                let data = JSON.parse(event.data);
                if(data.success) {
                    if(data.is_complete) {
                        vm.is_complete = 1;
                        vm.total_cnt = data.total_cnt;
                        vm.sent_cnt = data.total_cnt;
                        vm.dialog_title = '발송완료';
                        source.close();
                        if(vm.callback) {
                            vm.callback(data.student_list);
                        }
                    } else {
                        vm.total_cnt = data.total_cnt;
                        vm.sent_cnt = data.sent_cnt;
                    }
                }
            });
            
        },
    },
    
    beforeMount : function() {
    },
}
</script>