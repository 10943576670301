<template>
<div>
    <div :admin_id="this.row.admin_id" v-if="status==1">
        <span  :admin_id="this.row.admin_id" >
            {{name}}
            <span class="text-green" v-if="name_type==1">*</span>
        </span>
    </div>
    <div class="text-red-4" :admin_id="this.row.admin_id" v-else>
        <span  :admin_id="this.row.admin_id" >
            {{name}}
            <span v-if="name_type==1">*</span>
        </span>
        <q-icon name="close" />
    </div>
</div>
</template>


<script>
export default {
    props : {
        row : {
            type: Object,
            required: true
        },
    },
    data: function () {
        return {
            name_type : 0,
            name : '',
            admin_name : '',
            date_started : '',
            date_closed : '',
            status : 1,
        };
    },
    methods : {
    }, 
    beforeMount : function() {
        this.name = this.row.name;
        if(!this.name) { 
            this.name = this.row.admin_name; 
            this.name_type = 1;
        }
        this.date_started = this.row.date_started;
        this.date_closed = this.row.date_closed;
        this.status = this.row.status;
    },
}
</script>
