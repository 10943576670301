<template>
    <div  class="com-input-field q-field" 
         :class="{'q-field--error':error, 'q-field--outlined':outlined}">
        <div class="field__control">
            <slot></slot>
        </div>
        <div class="q-field__bottom" >
            <div class="q-field__messages" v-if="error">
                <div class="fadeInDown" >{{errorMessage}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import "./comInputField.css"

export default {
    props : {
        'error' : Boolean,
        'errorMessage' : String,
        'outlined' : Boolean,
    },
    data() {
        return {
        };
    },
    methods : {
    },
    beforeMount() {
    }
};
</script>

