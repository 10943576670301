<template>
<div>
    <q-dialog persistent="" v-model="is_open">
        <q-card class="q-px-sm q-pb-sm" style="width:400px; max-width:80vw;">
            <q-card-section>
                <div class="text-center text-h6">
                    출결메모
                </div>
            </q-card-section>
            <q-card-section class="q-py-none">
                <q-input type="textarea" outlined
                         v-model="form.memo" ></q-input>
            </q-card-section>
            <q-card-actions align="center" class="q-px-md">
                <q-btn color="grey" label="닫기" outline v-close-popup></q-btn>
                <q-btn color="primary" label="저장" @click="change_attend_memo" ></q-btn>
            </q-card-actions>
        </q-card>
    </q-dialog>
</div>
</template>


<script>
export default {
    data : function() {
        return {
            is_open : false,
            callback : null,
            class_inst_id : 0,
            user_id : 0,
            form : {
                memo : '',
            },
        };
    },
    methods : {
        /* open */
        open : function(student_attend, callback) {
            this.is_open = true;
            this.callback = callback;
            this.class_inst_id = student_attend.class_inst_id;
            this.user_id = student_attend.user_id;
            this.form.memo = student_attend.memo;
        },
        
        
        /* handle */
        change_attend_memo() {
            let vm = this;
            let memo = vm.form.memo;
            let url = `/api/admin/app/aca_erp/students/${this.user_id}/classes/class_insts/${this.class_inst_id}/attend_memo`;
            this.$c.io.put(url, {memo:memo}).then(function(response){
                let data = response.data;
                if(data.success) {
                    vm.is_open = false;
                    vm.$c.response_notify(1, "출결메모를 저장하였습니다.");
                    if( vm.callback ) {
                        vm.callback();
                    }
                }
            });
        },
    },
    
    beforeMount : function() {
    },
};
</script>