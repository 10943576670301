<template>
    <div>
        <q-btn outline size="sm" class="text-grey" @click="open_notice">학원정보</q-btn>
        <q-dialog persistent="" v-model="is_open">
            <q-card class="q-px-sm q-pb-sm" style="width:600px; max-width:80vw;">
                <q-card-section>
                    <div class="text-center text-h6">
                        공지정보
                    </div>
                </q-card-section>
                <q-card-section class="q-py-none">
                    <div class="center_notice_popup" v-html="notice" ></div>
                </q-card-section>
                <q-card-actions align="center" class="q-px-md">
                    <q-btn color="grey" label="닫기" outline v-close-popup></q-btn>
                </q-card-actions>
            </q-card>
        </q-dialog>
    </div>
</template>


<script>
export default {
    data : function() {
        return {
            is_open : false,
            notice : '',
        };
    },
    methods : {
        /* open */
        open_notice() {
            let vm = this;
            vm.is_open = true;
            vm.load_notice();
        },
        
        /* load */
        load_notice : function() {
            let vm = this;
            let url = `/api/admin/app/aca_erp/settings/center/notice`;
            vm.$c.io.get(url, {}).then( function(response) {
                let data = response.data;
                if(data['success']) {
                    vm.notice = data.setting.value;
                } 
            });
        },
    },
    
    beforeMount : function() {
    },
};
</script>

<style>
.center_notice_popup table td { padding:1px 4px; } 
</style>