<template>
<div class="teacher_list">
    <div class="mb1">
        <span class="border py3 pr3">
                <q-checkbox label="전체" val="" v-model="select_all" @update:model-value="change_select_all_teacher" />
        </span> 
        <q-chip class="bg-white"> 이거나 </q-chip>
    </div>
    <div class="mb3">
        <q-input outlined dense style="width:250px" placeholder="선생님이름검색"
                 v-model="teacher_name" @keydown.enter.prevent="search_teacher_name" >
            <template v-slot:append >
                <a @click="search_teacher_name">
                    <q-icon name="search" />
                </a>
            </template>
        </q-input>
    </div>
    <q-table :rows="teacher_list" separator="cell" flat bordered dense
             :rows-per-page-options="[0]" row-key="admin_id" 
             table-header-class="bg-blue-1"
             class="sticky-header-table" style="height:280px" 
             :key="key_teacher_list">
        <template v-slot:header>
            <tr class="bg-blue-1">
                <th class="w40px">
                    <q-checkbox dense v-model="is_selected_all" @update:model-value="change_select_all" />
                </th>
                <th class="text-left"> 
                    선생님이름
                </th>
            </tr>
        </template>
        <template v-slot:body="props">
            <tr class="pointer"
                :class="{active:props.row.is_selected}" @click="click_select(props.row)">
                <td>
                    <q-checkbox dense v-model="props.row.is_selected" @update:model-value="change_select(props.row)"/>
                </td>
                <td class="v-middle"> 
                    <teacher_name_renderer :row="props.row" />
                </td>
            </tr>
        </template>
        <template  v-slot:bottom>
                <q-pagination
                    v-model="pagination.page"
                    :max="pagination.total_pages"
                    size="sm"
                    input
                    @update:model-value="change_pagination"
                />
        </template>
    </q-table>
    
</div>
</template>


<script>
import teacher_name_renderer from '@_shared/class/teacher_name_renderer.vue';

export default {
    components : {
        teacher_name_renderer,
    },
    props : {
        form : Object,
    },
    data : function() {
        return {
            key_teacher_list : 1,
            teacher_name : '',
            select_all : true,
            teacher_list : [],
            pagination : {
                page: 1,
                limit : 20,
                total_pages: 0,
                total_count: 0
            },
            is_selected_all:false,
        }
    },
    computed : {
        
    },
    methods : {
        
        /* select */
        change_select() {
            this.check_select_all();
            this.change_selected();
        },
        change_select_all() {
            this.teacher_list.forEach(x => {
                x.is_selected = this.is_selected_all;
            });
            this.select_all = !this.is_selected_all;
            this.change_selected();
        },
        check_select_all() {
            let is_all = true;
            let is_one = false;
            this.teacher_list.forEach(x => {
                if(x.is_selected == false) {
                    is_all = false;
                } else {
                    is_one = true;
                }
            });
            this.is_selected_all = is_all;
            this.select_all = !is_one;
        },
        clear_selection() {
            this.teacher_list.forEach(x => {
                x.is_selected = false;
            });
            this.is_selected_all = false;
            this.change_selected();
        },
        
        /* change */
        search_teacher_name() {
            this.pagination.page = 1;
            this.load_teacher_list();
        },
        change_pagination(page) {
            this.pagination.page = page;
            this.load_teacher_list();
        },
        change_select_all_teacher() {
            this.teacher_name = '';
            this.clear_selection();
            this.change_selected();
        },
        click_select(row) {
            row.is_selected=!row.is_selected; //토글
            this.check_select_all();
            this.change_selected();
        },
        change_selected() {
            let selected = [];
            this.teacher_list.forEach(x => {
                if(x.is_selected) {
                    selected.push(x);
                }
            });
            this.$emit('selected_teacher', {teacher_list:selected});
        },
        
        
        /* load */
        load_teacher_list : function() {
            let vm = this;
            let filter = {};
            if(vm.teacher_name) {
                filter['teacher_name'] = {"filterType": "text","type": "startswith","filter": vm.teacher_name};
            }
            if(!vm.form.include_close) {
                filter['status'] = {"filterType": "number","type": "=","filter": 1};
            }
            let form_data = {
                page : vm.pagination.page,
                limit : vm.pagination.limit,
                filter : JSON.stringify(filter),
                sort : '[]',
                tzoffset : this.$c.tzoffset,
            };
            this.$c.io.get('/api/admin/app/aca_erp/teachers', form_data).then( function(response) {
                var data = response.data;
                if(data['success']) {
                    for(let i=0; i<data['teacher_list'].length; i++) {
                        let p = data['teacher_list'][i];
                        p.teacher_name = '';
                    }
                    vm.teacher_list = data['teacher_list'];
                    vm.pagination.page = data.pagination.page;
                    vm.pagination.total_count = data.pagination.total_count;
                    vm.pagination.total_pages = data.pagination.total_pages;
                    //테이블 화면 갱신 + 학교리스트 갱신
                    vm.key_teacher_list += 1;
                } 
                vm.clear_selection();
            });
        },
    },
    beforeMount : function() {
        this.load_teacher_list();
    },
    mounted : function() {
    },
}
</script>