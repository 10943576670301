<template>
    <div class="student_name_renderer" :class="[status_code]" >
        <span>{{name}}</span>
        <q-icon name="close" v-if="student.status_code=='holding' || student.status_code=='drop'" />
    </div>
</template>



<script>
export default {
    props : {
        student : { type:Object, required:true },
    },
    data : function() {
        return {
            name : '',
            status_code : '',
        };
    },
    methods : {
    },
    beforeMount : function() {
        this.name = this.student.name;
        if(!this.name) { this.name = this.student.user_name; }
        this.status_code = this.student.status_code;
    },
};
</script>

<style>
.student_name_renderer.waiting {
    color : gray;
}
.student_name_renderer.present {
    color : black;
}
.student_name_renderer.holding {
    color : deep-orange;
}
.student_name_renderer.drop {
    color : red;
}
.selected_student .student_name_renderer.waiting {
    color : white;
}
.selected_student .student_name_renderer.present {
    color : white;
}
.selected_student .student_name_renderer.holding {
    color : orange;
}
.selected_student .student_name_renderer.drop {
    color : coral;
}
</style>
