<template>
<div>
    <q-dialog persistent="" v-model="is_open">
        <q-card class="q-px-sm q-pb-sm" style="width:700px; max-width:80vw;">
            <q-card-section>
                <div class="text-center text-h6">미션 {{dialog_title}}</div>
            </q-card-section>
            <q-card-section class="q-py-none">
                
                <q-banner class="mission bg-grey-2 q-my-sm" :key="m.id" v-for="(m,idx) in mission_list">
                    <table class="w100p">
                    <tbody>
                        <tr>
                            <td class="index">
                                <div class="pt3">
                                    {{idx+1}}
                                </div>
                            </td>
                            <td class="mission_type">
                                <q-select outlined emit-value map-options 
                                          class="bg-white"
                                          v-model="m.mission_type" 
                                          :options="mission_type_options" >
                                </q-select>
                            </td>
                            <td class="description">
                                <q-input outlined class="bg-white"
                                         v-model="m.description" />
                            </td>
                            <td class="button">
                                <q-btn outline class="mr2" label="지시추가" padding="2px 8px" 
                                       @click="add_todo(m)"/>
                                <q-btn outline label="삭제" padding="2px 8px" 
                                       :disable="idx===0"
                                       @click="delete_mission(m)" />
                            </td>
                        </tr>
                        <tr :key="t.id" v-for="(t, t_idx) in m.todo_list">
                            <td></td>
                            <td>
                                <div class="pt3">
                                    {{idx+1}}-{{t_idx+1}}
                                </div>
                            </td>
                            <td>
                                <q-input outlined class="bg-white"
                                         v-model="t.description" />
                            </td>
                            <td class="button">
                                <q-select outlined emit-value map-options 
                                          class="d-inline-block bg-white w110px mr2"
                                          v-model="t.target_type" 
                                          :options="target_type_options" >
                                </q-select>
                                <q-btn outline label="삭제" padding="2px 8px" 
                                       @click="delete_todo(m, t.id)"/>
                            </td>
                        </tr>
                    </tbody>
                    </table>
                </q-banner>
                <div class="text-center mt2">
                    <q-btn color="primary" label="미션추가" padding="2px 12px"
                           @click="add_mission" ></q-btn>    
                </div>

            </q-card-section>
            <q-card-actions align="right" class="q-px-md" style="align-items:flex-start">
                <div class="q-pl-sm mt3">제출일:</div>
                <div class="q-px-sm">
                    <q-input class="w160px" outlined 
                            :error="(form_error.submit_date)?true:false" 
                            :error-message="form_error.submit_date"
                            v-model="form.submit_date"  >
                        <template v-slot:append>
                            <q-icon name="event" class="cursor-pointer">
                                <q-popup-proxy transition-show="scale" transition-hide="scale" 
                                               ref="popup_submit_date" >
                                    <q-date v-model="form.submit_date" :mask="date_format" 
                                           @update:model-value="()=>$refs.popup_submit_date.hide()" >
                                        <div class="row items-center justify-end">
                                            <q-btn unelevated v-close-popup label="Close" color="primary" flat />
                                        </div>
                                    </q-date>
                                </q-popup-proxy>
                            </q-icon>
                        </template>
                    </q-input>
                </div>

                <q-btn color="grey" label="취소" outline v-close-popup></q-btn>
                <q-btn @click="delete_data" color="negative" label="미션 삭제"  
                       v-if="form.mode=='edit'"></q-btn>
                <q-btn @click="save_data" color="primary" label="미션 저장" ></q-btn>
            </q-card-actions>
        </q-card>
    </q-dialog>
</div>
</template>


<script>
export default {
    components : {
    },
    data : function() {
        return {
            is_open : false,
            dialog_title : '',
            callback : null,
            class_id : 0,
            class_inst_id : 0,
            class_work_id : 0,
            form: {
                mode : '',
                mission : '',
                submit_date : '',
            },
            form_error : {
                submit_date : '',
            },
            mission_list : [],
            mission_type_options : [
                { label : '수업', value: 'class' },
                { label : '숙제', value: 'homework' },
                { label : '준비', value: 'prepare' },
            ],
            target_type_options  : [
                { label : '담임', value: 'class_teacher' },
                { label : '강사', value: 'teacher' },
                { label : '학생', value: 'student' },
            ],
            coach_type_options  : [
                { label : 'Pass/Fail', value: 'pass_fail' },
                { label : '자유입력', value: 'free' },
                { label : '없음', value: '' },
            ],
        };
    },
    computed : {
        date_format : function() {
            return this.$store.state.date_format;
        },
        
    },
    methods : {
        /* open */
        open : function(mode, form_data, callback) {
            this.set_data(mode, form_data);
            this.callback = callback;
            this.is_open = true;
        },

        /* set */
        set_data(mode, form_data) {
            this.dialog_title = '';
            if(mode=='add') { this.dialog_title = '추가'; }
            else if(mode=='edit') { this.dialog_title = '수정'; }
            this.form.mode = mode;
            this.class_id = form_data.class_id;
            this.class_inst_id = form_data.class_inst_id;
            this.class_work_id = form_data.class_work_id;
            this.form.mission = form_data.mission;
            this.form.submit_date = form_data.submit_date;
            let mission_list = [];
            try {
                mission_list = JSON.parse(form_data.mission);
            } catch(e) {
                mission_list = [];
            }
            this.mission_list = mission_list;
            if(this.mission_list.length===0) {
                this.add_mission();
            }
            //form에러 제거
            this.$c.form.clear_form(this.form_error);
            //console.log(form_data);
        },

        /* mission */
        add_mission() {
            let last_id = 0;
            this.mission_list.forEach(m =>{
                if(m.id>last_id) { last_id = m.id; }
            });
            let mission = {
                'id' : last_id + 1,
                'description': '',
                'mission_type' : 'class',
                'sort_order' : 0,
                'todo_list' : [],
            };
            this.mission_list.push(mission);
        },
        add_todo(mission) {
            let last_id = 0;
            mission.todo_list.forEach(m =>{
                if(m.id>last_id) { last_id = m.id; }
            });
            let todo = {
                'id' : last_id + 1,
                'description': '',
                'coach_type' : '',
                'target_type' : 'student',
            };
            mission.todo_list.push(todo);
        },

        delete_mission(mission) {
            this.mission_list = this.mission_list.filter((m)=>{
                return (m.id!==mission.id);
            });
        },
        delete_todo(mission, idx) {
            mission.todo_list = mission.todo_list.filter((t)=>{
                return (t.idx!==idx);
            });
        },
        
        /* handle */
        save_data : function() {
            let vm = this;
            let url = `/api/admin/app/aca_erp/classes/${this.class_id}/class_insts/${this.class_inst_id}/class_works/${this.class_work_id}`;
            let form_data = {
                mode : vm.form.mode,
                mission : JSON.stringify(vm.mission_list),
                submit_date : vm.form.submit_date,
                tzoffset: vm.$c.tzoffset,
            };
            this.$c.form.clear_form(this.form_error);
            this.$c.io.put(url, form_data).then(function(response) {
                let data = response.data;
                if(data.success) {
                    vm.is_open = false;
                    if(vm.callback) { vm.callback(vm.form.mode); }
                } else {
                    if(data.error) {
                        for(let key in vm.form_error) {
                            vm.form_error[key] = data.error[key]
                        }
                    }
                    if(data.message) {
                        vm.$c.response_notify(0, data.message);
                    }
                    console.log(data);
                }
            });
        },
        
        delete_data : function() {
            let vm = this;
            this.$q.dialog({ title: '<div class="text-center">확인</div>', 
                message: '<div class="text-center">삭제할까요?</div>', cancel: true, html: true
            }).onOk(() => {
                let url = `/api/admin/app/aca_erp/classes/${this.class_id}/class_insts/${this.class_inst_id}/class_works/${this.class_work_id}`;
                vm.$c.io.delete(url, {}).then(function(response) {
                    let data = response.data;
                    if(data.success) {
                        vm.is_open = false;
                        if(vm.callback) { vm.callback('delete'); }
                    }
                });
            });
        },
    },
    
    beforeMount : function() {
    },
}
</script>


<style scope>
.mission .index {
    width:30px;
}
.mission .mission_type {
    width:110px;
    
}
.mission .button {
    text-align: right;
    width:150px;
}
</style>