<template>
<div class="date_option" style="padding:48px 0 0">
    <div class="border q-pa-sm q-px-md">
        <div>
            <div class="row mb2">
                    <!--
                    <div class="py2 w100px">
                        현원기준일 :
                    </div> 
                    <div class="w60px">
                        <q-input dense outlined  v-model="form.current_status_day" />
                    </div> -->
                    
            </div>
        </div>
        <div>
            <div class="row mb2">
                    <div class="py2 w100px">
                        검색년월 :
                    </div>
                    <q-btn outline size='sm' icon='chevron_left' class="px3" @click="prev_month" ></q-btn>
                    <span class="px3 py2"> 
                        {{form.year}}년 {{form.month}}월 
                            <q-btn outline icon="event" class="ft10 px2" size="sm">
                                <q-popup-proxy transition-show="scale" transition-hide="scale" ref="popup_today" >
                                    <q-date default-view="Months" years-in-month-view emit-immediately
                                            v-model="form.month_date" :mask="month_format" @update:model-value="change_date" 
                                            ref="input_date" >
                                        <div class="row items-center justify-end">
                                            <q-btn unelevated v-close-popup label="Close" color="primary" flat />
                                        </div>
                                    </q-date>
                                </q-popup-proxy>
                            </q-btn>
                    </span>
                    <q-btn outline size='sm' icon='chevron_right' class="px3" @click="next_month" ></q-btn>
            </div>
            
        </div>
    </div>
</div>
</template>

<script>
export default {
    data : function() {
        return {
            form : {
                month_date : '',
                year: 0,
                month : 0,
                start_date : '',
                end_date : '',
                current_status_day : 1,
            },
        }
    },
    computed : {
        date_format : function() {
            return this.$store.state.date_format;
        },
        month_format() {
            return 'YYYY-MM';
        },
    },
    methods : {
        set_date(this_day) {
            if( (this_day instanceof Date)==false ) {
                this_day = this_day.to_date();
            }
            let this_month_start = new Date(this_day.getFullYear(), this_day.getMonth(), 1);
            let this_month_end = new Date(this_day.getFullYear(), this_day.getMonth() + 1, 0);
            //iso date로 변환
            this.form.year = this_month_start.getFullYear();
            this.form.month = this_month_start.getMonth() + 1;
            this.form.day_count = this_month_end.getDate();
            this.form.start_date = this.$c.format_date(this_month_start,this.$store.state.date_format);
            this.form.end_date = this.$c.format_date(this_month_end,this.$store.state.date_format);
            let start_date_list = this.form.start_date.split('-');
            this.form.month_date = start_date_list[0]+'-'+start_date_list[1];
            //현원기준일 overflow 세팅
            if(this.form.current_status_day<1) { this.form.current_status_day=1; }
            if(this.form.current_status_day>this.form.day_count) {
                this.form.current_status_day=this.form.day_count;
            }
            
        },
        
        change_date(_, reason, ) {
            if (reason == 'month') {
                this.$refs.popup_today.hide();
                this.set_date(this.form.month_date+'-01');
            } else if (reason == 'year') { // 년도 선택시 달 선택 화면으로 이동
                this.$refs.input_date.setView('Months');
            }
        },
        
        /* click */
        prev_month() {
            let this_day = this.form.start_date;
            this_day = this_day.to_date();
            this_day = new Date(this_day.getFullYear(), this_day.getMonth()-1, 1);
            this.set_date(this_day);
        },
        next_month() {
            let this_day = this.form.start_date;
            this_day = this_day.to_date();
            this_day = new Date(this_day.getFullYear(), this_day.getMonth()+1, 1);
            this.set_date(this_day);
        },
    },
    beforeMount : function() {
        let today = new Date();
        this.set_date(today);
    },
    mounted : function() {
    },
    beforeUnmount : function() {
    },
}
</script>
