<template>
<div>
    <q-dialog persistent="" v-model="is_open">
        <q-card class="q-px-sm q-pb-sm" >
            <q-card-section>
                <div class="text-center text-h6">
                    출결 : {{class_name}}
                    <span class="ft12" >({{class_student_list.length}}명)</span>
                </div>
            </q-card-section>
            <q-card-section class="q-py-none">
                <q-scroll-area class="border py2 px3" style="height:500px;width:500px">
                    <q-list>
                            <q-item clickable v-ripple class="px0" 
                                    v-for="cc in class_student_list"
                                    :key="cc.class_student_id" >
                                <q-item-section avatar>
                                    <q-avatar>
                                        <template v-if="cc.resized_image">
                                            <q-img :src="cc.resized_image" />
                                        </template>
                                        <template v-else>
                                            <img src="/image/user/student_m.png" >
                                        </template>
                                    </q-avatar>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label>
                                        <div class="text-blue" style="width:100px;">
                                            <span >{{cc.student_name}}</span>
                                        </div>
                                    </q-item-label>
                                    <q-item-label caption>
                                        {{cc.school_name}}
                                    </q-item-label>
                                </q-item-section>
                                <q-item-section side top>
                                    <div class="p-relative text-left q-gutter-x-xs" style="width:260px">
                                        <q-btn class="px3" :class="get_student_status_color(cc.attend_status, status)" 
                                               @click="change_attendance_status(cc, status)"
                                               v-for="status in visible_attend_status_list"
                                               :key="status" >
                                            {{get_status_label(status)}}
                                        </q-btn>
                                        <q-btn class="pl3 q-pr-md p-relative" :class="get_other_status_color(cc.attend_status)" >
                                            {{get_other_status_label(cc.attend_status)}}
                                            <div class="p-absolute p-t0 pt2" style="right:-2px" >
                                                <q-icon name="chevron_right" />
                                            </div>
                                            <q-menu>
                                                <q-bar class="bg-white">
                                                    <div></div>
                                                    <q-space />
                                                    <q-btn icon="close" v-close-popup flat dense/>
                                                </q-bar>
                                                <q-list style="min-width: 100px">
                                                    <q-item clickable v-close-popup @click="change_attendance_status(cc, status)"
                                                        v-for="status in menu_attend_status_list" 
                                                        :key="status" >
                                                        <q-chip :label="cc.student_name" :class="get_status_color(status)"  />
                                                        <q-item-section  class="q-ml-sm">
                                                            {{get_status_label(status)}}</q-item-section>
                                                    </q-item>
                                                    <q-separator />
                                                    <q-item clickable v-close-popup @click="open_attendance_memo(cc)" >
                                                        <q-chip :label="cc.student_name" outline />
                                                        <q-item-section  class="q-ml-sm">출결메모</q-item-section>
                                                    </q-item>
                                                    <q-item clickable v-close-popup @click="open_makeup_class(cc)" >
                                                        <q-chip :label="cc.student_name" outline />
                                                        <q-item-section  class="q-ml-sm">보강생성</q-item-section>
                                                    </q-item>
                                                </q-list>
                                            </q-menu>
                                        </q-btn>
                                        <div class="p-absolute p-t0" style="right:2px">
                                            <q-btn class="pl3 q-pr-md p-relative" :disable="is_disable">
                                                문자
                                                <div class="p-absolute p-t0 pt2" style="right:-2px" >
                                                    <q-icon name="chevron_right" />
                                                </div>
                                                <q-menu>
                                                    <q-bar class="bg-white">
                                                        <div></div>
                                                        <q-space />
                                                        <q-btn icon="close" v-close-popup flat dense/>
                                                    </q-bar>
                                                    <q-list style="min-width: 100px">
                                                        <q-item clickable v-close-popup @click="send_message(cc, msg_type)"
                                                            v-for="msg_type in menu_message_list" 
                                                            :key="msg_type" >
                                                            <q-chip :label="cc.student_name" :class="get_message_color(msg_type)"  />
                                                            <q-item-section  class="q-ml-sm">
                                                                {{get_message_label(msg_type)}}</q-item-section>
                                                        </q-item>
                                                    </q-list>
                                                </q-menu>
                                            </q-btn>
                                        </div>
                                    </div>
                                </q-item-section>
                            </q-item>
                    </q-list>
                </q-scroll-area>
            </q-card-section>
            <q-card-actions align="center" class="q-px-md">
                <q-btn color="grey" label="닫기" outline v-close-popup></q-btn>
            </q-card-actions>
        </q-card>
    </q-dialog>
    
    <dialog_student_makeup_class ref="dialog_student_makeup_class" />
    <dialog_student_attend_memo ref="dialog_student_attend_memo" />
</div>
</template>

<script>
import dialog_student_makeup_class from './dialog_student_makeup_class.vue';
import dialog_student_attend_memo from './dialog_student_attend_memo.vue';

export default {
    components : {
        dialog_student_makeup_class,
        dialog_student_attend_memo,
    },
    data : function() {
        return {
            is_open : false,
            is_disable : false,
            callback : null,
            class_date : '',
            class_name : '',
            class_id : 0,
            class_course_id : 0,
            class_inst_id : 0,
            class_student_list : [],
            visible_attend_status_list : ['attend', 'late', 'absent'],
            menu_attend_status_list : ['', 'online'],
            menu_message_list : ['come_in', 'go_out'],
        };
    },
    computed: {
        center_api() {
            return this.$store.state.center_api;
        },
        attend_status_list() {
            return this.$store.state.attend_status_list;
        },
    },
    methods : {
        /* open */
        open : function(class_inst, callback) {
            this.class_id = class_inst.class_id;
            this.class_course_id = class_inst.class_course_id;
            this.class_inst_id = class_inst.class_inst_id;
            this.class_date = '';
            this.class_name = '';
            this.class_student_list = [];
            this.callback = callback;
            this.is_open = true;
            //전송일이 오늘과 일치하는지 체크
            let now = new Date()
            let now_date = this.$c.format_date(now, "YYYY-MM-DD");
            this.is_disable = (now_date!=class_inst.class_date);
            this.load_class_student_attend(this.class_id, this.class_inst_id);
        },
        open_attendance_memo(student) {
            let vm = this;
            let student_attend = {
                class_id : this.class_id,
                class_course_id : this.class_course_id,
                class_inst_id : this.class_inst_id,
                user_id : student.user_id,
                memo : student.attend_memo,
            };
            this.$refs.dialog_student_attend_memo.open(student_attend, function() {
                vm.load_class_student_attend(vm.class_id, vm.class_inst_id);
                if( vm.callback ) {
                    vm.callback();
                }
            });
        },
        open_makeup_class(student) {
            let vm = this;
            let student_attend = {
                class_id : this.class_id,
                class_course_id : this.class_course_id,
                class_inst_id : this.class_inst_id,
                user_id : student.user_id,
            };
            this.$refs.dialog_student_makeup_class.open(student_attend, function() {
                vm.load_class_student_attend(vm.class_id, vm.class_inst_id);
                if( vm.callback ) {
                    vm.callback();
                }
            });
        },
        
        /* message */
        get_message_label: function (msg_type) {
            let label_dict = {
                'come_in': '등원 발송',
                'go_out': '하원 발송',
            };
            if (this.$c.hasProperty(label_dict,msg_type) == true) {
                return label_dict[msg_type];
            }
            return '';
        },
        get_message_color: function (msg_type) {
            let color_dict = {
                'come_in': 'bg-positive text-white',
                'go_out': 'bg-negative text-white',
            };
            if (this.$c.hasProperty(color_dict,msg_type) == true) {
                return color_dict[msg_type];
            }
            return 'bg-grey text-white';
        },
        
        
        /* status */
        get_status_label: function (status) {
            let label_dict = {
                'attend': '출석',
                'absent': '결석',
                'late': '지각',
                'online': '온라인',
            };
            if (this.$c.hasProperty(label_dict,status) == true) {
                return label_dict[status];
            }
            return '대기';
        },
        get_status_color: function (status) {
            let color_dict = {
                'attend': 'bg-positive text-white',
                'absent': 'bg-negative text-white',
                'late': 'bg-warning text-white',
                'online': 'bg-purple text-white',
            };
            if (this.$c.hasProperty(color_dict,status) == true) {
                return color_dict[status];
            }
            return 'bg-grey text-white';
        },
        get_student_status_color(attend_status, status) {
            if(attend_status==status) {
                return this.get_status_color(status);
            }
            return this.get_status_color('');
        },
        get_other_status_color(attend_status) {
            if(this.menu_attend_status_list.indexOf(attend_status)>=0) {
                return this.get_status_color(attend_status)
            }
            return this.get_status_color('')
        },
        get_other_status_label(attend_status) {
            if(this.menu_attend_status_list.indexOf(attend_status)>=0) {
                return this.get_status_label(attend_status)
            }
            return this.get_status_label('')
        },
        
        /* format */
        format_date : function(datetime) {
            if(!datetime) datetime='';
            var date = datetime.to_localdate();
            if(date) { return this.$c.format_date(date,this.$store.state.date_format); }
            return "";
        },
        
        /* load */
        load_class_student_attend(class_id, class_inst_id) {
            let vm = this;
            //filter 사용안함.
            //--> 이유 : 날짜를 변경할 수 있으므로, 날짜에 따라서는 퇴반이었던 학생의 출결도 가져와야 하기 때문
            //let filter = {
            //  is_alive: {"filterType": "number","type": "=","filter": 1},
            //};
            let student_form_data = { tzoffset : this.$c.tzoffset, };
            let url_class_inst = `/api/admin/app/aca_erp/classes/${class_id}/class_insts/${class_inst_id}`;
            let url_class_student = `/api/admin/app/aca_erp/classes/${class_id}/class_insts/${class_inst_id}/class_students`;
            let url_student_attendance = `/api/admin/app/aca_erp/classes/${class_id}/class_insts/${class_inst_id}/attendances`;
            vm.axios.all( [ this.$c.io.get(url_class_inst),
                this.$c.io.get(url_class_student, student_form_data), this.$c.io.get(url_student_attendance)]).then(
                vm.axios.spread( (res0, res1, res2) => {
                    let data0 = res0.data;
                    let data1 = res1.data;
                    let data2 = res2.data;
                    //수업정보
                    if(data0.success) {
                        vm.class_date = data0.class_inst.class_date;
                        vm.class_name = data0.class_inst.class_name;
                    }
                    //학생리스트
                    if(data1.success) {
                        let class_student_dict = {};
                        //class_student는 퇴반학생도 같이 들어옴
                        //--> 이유 : 수업날짜를 바꿀수 있으므로, 날짜에 따라 퇴반 학생도 가져와야 함.
                        vm.class_student_list = [];
                        data1.class_student_list.forEach(x => {
                            let date_started = vm.format_date( x.date_started);
                            let date_closed = vm.format_date( x.date_closed);
                            if( vm.class_date >= date_started && (x.is_alive==1 || vm.class_date<date_closed) ) {
                                if(vm.$c.hasProperty(class_student_dict,x.user_id)==false) {
                                    class_student_dict[x.user_id] = x;
                                    x.is_selected = false;
                                    x.student_name = (x.name)? x.name : x.user_name;
                                    vm.class_student_list.push(x);
                                    //console.log(x);
                                }
                            }
                        });
                    }
                    //출결데이터
                    if(data2.success) {
                        let student_attend_list = data2.student_attend_list;
                        let student_attend_dict = {};
                        student_attend_list.forEach( x =>{
                            student_attend_dict[x.user_id] = x;
                        });
                        vm.class_student_list.forEach(x => {
                            x.attend_status = '';
                            x.attend_memo = '';
                            if(vm.$c.hasProperty(student_attend_dict,x.user_id)) {
                                let ca = student_attend_dict[x.user_id];
                                x.attend_status = ca.attend_status;
                                x.attend_memo = ca.memo;
                            }
                        });
                    }
                })
            );
        },
        
        /* handle */
        send_message(student, msg_type) {
            let check_type='';
            if(msg_type=='come_in') { check_type='checkin'; }
            else if(msg_type=='go_out') { check_type='checkout'; }
            else {
                return;
            }
            
            let vm = this;
            let student_id = student.user_id;
            let message_code = this.$c.uid();
            let now = new Date();
            let date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            let date_str = this.$c.format_date(date, "YYYY-MM-DD");
            let datetime_str = this.$c.format_date(now, "YYYY-MM-DD HH:mm:ss");
            let form_data = {
                student_id : student_id,
                message_code : message_code,
                check_date: date_str,
                check_datetime: datetime_str,
                tzoffset : this.$c.tzoffset,
                secret_key : '',
            };
            let msg_company = this.center_api.msg_company;
            vm.$c.io.put( `/api/admin/app/aca_erp/util/message/agent/${msg_company}/time_cards/${check_type}`, 
            form_data).then( function(response) {
                let data = response.data;
                if(data['success']) {
                    vm.is_open = false;
                    if(vm.callback) {
                        let message = "메시지를 발송하였습니다.";
                        /*
                        if(msg_company=='my_phone') {
                            message = "메시지를 생성하였습니다.<br/>앱에서 '문자발송'으로 문자를 발송해주세요";
                        }*/
                        if(msg_type=='come_in') { message ='등원'+message; }
                        else if(msg_type=='go_out') { message ='하원'+message; }
                        vm.$c.response_notify(1, message);
                        if( vm.callback ) {
                            vm.callback();
                        }
                    }
                } else {
                    if(data.message) {
                        vm.$c.response_notify(0, data.message);
                    }
                }
            });
        },
        
        change_attendance_status(student_attend, status) {
            let vm = this;
            let user_id = student_attend.user_id;
            let url = `/api/admin/app/aca_erp/students/${user_id}/classes/class_insts/${this.class_inst_id}/attend_status`;
            this.$c.io.put(url, {attend_status:status},).then(function(response){
                let data = response.data;
                if(data.success) {
                    let message = vm.get_status_label(status)+" 처리하였습니다.";
                    vm.$c.response_notify(1, message);
                    vm.load_class_student_attend(vm.class_id, vm.class_inst_id);
                    if( vm.callback ) {
                        vm.callback();
                    }
                }
            });
        },
    },
    
    beforeMount : function() {
    },
};
</script>