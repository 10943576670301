<template>
<div>
    <q-dialog persistent="" v-model="is_open">
        <q-card class="q-px-sm q-pb-sm" style="width:700px; max-width:80vw;">
            <q-card-section>
                <div class="text-center text-h6">미션 결과</div>
            </q-card-section>
            <q-card-section class="q-py-none">
                <q-timeline color="primary">
                    <q-timeline-entry>
                        <template v-slot:title>
                            <div class="ft14">반 공통 미션</div>
                        </template>
                        <q-banner class="mission_result bg-grey-2 q-my-sm" :key="m.id" 
                                  v-for="(m,idx) in class_mission_list">
                            <table class="w100p">
                            <tbody>
                                <tr>
                                    <td class="index">
                                        {{idx+1}}
                                    </td>
                                    <td class="mission_type">
                                        <q-chip square class="px3 ma0"
                                                :color="get_mission_type_color(m.mission_type)"
                                                text-color="white">
                                            {{get_mission_type(m.mission_type)}}
                                        </q-chip>
                                        <q-btn outline size="sm" class="mt2" padding="1px 3px"
                                               v-if="m.todo_list.length>0"
                                               @click="m.is_view_direction=!m.is_view_direction" >
                                            <q-icon name="remove" v-if="m.is_view_direction" />
                                            <q-icon name="add" v-else />
                                        </q-btn>
                                    </td>
                                    <td class="description" colspan="2">
                                        {{m.description}}
                                        <div>
                                            완료 : 
                                            {{m.result.check_date}}
                                        </div>
                                        <div class="row">
                                            <div class="w60px">피드백 :</div>
                                            <div class="col">
                                                <q-input dense class="feedback"
                                                         v-model="m.result.feedback" />
                                            </div>
                                        </div>
                                    </td>
                                    <td class="button">
                                        <q-radio v-model="m.result.is_pass" :val="1" label="Pass" 
                                                 @update:model-value="change_pass(m.result)" />
                                        <q-radio v-model="m.result.is_pass" :val="0" label="Fail" 
                                                 @update:model-value="change_pass(m.result)" />
                                    </td>
                                </tr>
                                <template v-if="m.is_view_direction">
                                <tr :key="t.id" 
                                    v-for="(t, t_idx) in m.todo_list">
                                    <td></td>
                                    <td>
                                        {{idx+1}}-{{t_idx+1}}
                                    </td>
                                    <td class="w60px">
                                        <q-chip square class="px3 ma0"
                                                :color="get_target_type_color(t.target_type)"
                                                text-color="white">
                                            {{get_target_type(t.target_type)}}
                                        </q-chip>
                                    </td>
                                    <td>
                                        {{t.description}}
                                    </td>
                                    <td class="button">
                                    </td>
                                </tr>
                                </template>
                            </tbody>
                            </table>
                        </q-banner>
                    </q-timeline-entry>
                    <q-timeline-entry>
                        <template v-slot:title>
                            <div class="ft14">학생 개별 미션</div>
                        </template>
                        <q-banner class="mission_result bg-grey-2 q-my-sm" :key="m.id" 
                                  v-for="(m,idx) in mission_list">
                            <table class="w100p">
                            <tbody>
                                <tr>
                                    <td class="index">
                                        <div class="pt3">
                                            {{idx+1}}
                                        </div>
                                    </td>
                                    <td class="mission_type">
                                        <q-chip square class="px3 ma0"
                                                :color="get_mission_type_color(m.mission_type)"
                                                text-color="white">
                                            {{get_mission_type(m.mission_type)}}
                                        </q-chip>
                                        <q-btn outline size="sm" class="mt2" padding="1px 3px"
                                               v-if="m.todo_list.length>0"
                                               @click="m.is_view_direction=!m.is_view_direction" >
                                            <q-icon name="remove" v-if="m.is_view_direction" />
                                            <q-icon name="add" v-else />
                                        </q-btn>
                                    </td>
                                    <td class="description" colspan="2">
                                        {{m.description}}
                                        <div>
                                            완료 : 
                                            {{m.result.check_date}}
                                        </div>
                                        <div class="row">
                                            <div class="w60px">피드백 :</div>
                                            <div class="col">
                                                <q-input dense class="feedback"
                                                         v-model="m.result.feedback" />
                                            </div>
                                        </div>
                                    </td>
                                    <td class="button">
                                        <q-radio v-model="m.result.is_pass" :val="1" label="Pass" 
                                                 @update:model-value="change_pass(m.result)" />
                                        <q-radio v-model="m.result.is_pass" :val="0" label="Fail" 
                                                 @update:model-value="change_pass(m.result)" />
                                    </td>
                                </tr>
                                <template v-if="m.is_view_direction">
                                <tr :key="t.id" v-for="(t, t_idx) in m.todo_list">
                                    <td></td>
                                    <td>
                                        <div class="pt3">
                                            {{idx+1}}-{{t_idx+1}}
                                        </div>
                                    </td>
                                    <td class="w60px">
                                        <q-chip square class="px3 ma0"
                                                :color="get_target_type_color(t.target_type)"
                                                text-color="white">
                                            {{get_target_type(t.target_type)}}
                                        </q-chip>
                                    </td>
                                    <td>
                                        {{t.description}}
                                    </td>
                                    <td class="button">
                                        
                                    </td>
                                </tr>
                                </template>
                            </tbody>
                            </table>
                        </q-banner>
                    </q-timeline-entry>
                    <q-timeline-entry>
                        <div class="submit_date text-center q-mb-md">
                        <com-input-field class="d-inline-block" style="width:240px"
                                         :error="(form_error.status_code)?true:false"
                                         :error-message="form_error.status_code" >
                            <div class="text-center q-gutter-x-sm">
                                <q-radio v-model="form.status_code" val="complete" label="완료확인" />
                                <q-radio v-model="form.status_code" val="skip" label="스킵" />
                                <div class="d-inline-block p-relative">
                                    <q-radio v-model="form.status_code" val="return" label="재검토" />
                                    <div class="p-absolute p-t0" style="left:100%;"
                                         v-if="form.status_code==='return'">
                                        <div class="q-pl-sm pt2">기한연장:</div>
                                        <div class="q-px-sm">
                                            <q-input class="w140px" outlined 
                                                    :error="(form_error.submit_date)?true:false" 
                                                    :error-message="form_error.submit_date"
                                                    v-model="form.submit_date" >
                                                <template v-slot:append>
                                                    <q-icon name="event" class="cursor-pointer">
                                                        <q-popup-proxy transition-show="scale" transition-hide="scale" 
                                                                       ref="popup_submit_date" >
                                                            <q-date v-model="form.submit_date" :mask="date_format" 
                                                                   @update:model-value="()=>$refs.popup_submit_date.hide()" >
                                                                <div class="row items-center justify-end">
                                                                    <q-btn unelevated v-close-popup label="Close" color="primary" flat />
                                                                </div>
                                                            </q-date>
                                                        </q-popup-proxy>
                                                    </q-icon>
                                                </template>
                                            </q-input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </com-input-field>
                        </div>
                        <div class="text-center q-gutter-x-sm">
                            <q-btn color="grey" label="취소" outline v-close-popup></q-btn>
                            <q-btn @click="save_data" color="primary" label="결과 저장"></q-btn>
                        </div>
                    </q-timeline-entry>
                </q-timeline>

            </q-card-section>
        </q-card>
    </q-dialog>
</div>
</template>


<script>
import comInputField from '@/components/comInputField.vue';
import mission_js from '@/app/store/mission.js';

export default {
    components : {
        comInputField,
    },
    data : function() {
        return {
            is_open : false,
            callback : null,
            user_id : 0,
            class_id : 0,
            class_inst_id : 0,
            class_work_id : 0,
            class_mission_list : [],
            mission_list : [],
            form : {
                status_code : '',
                submit_date: '',
            },
            form_error : {
                status_code: '',
                submit_date: '',
            },
        };
    },
    computed : {
        i18n() {
            return this.$store.state.i18n;
        },
        date_format : function() {
            return this.$store.state.date_format;
        },
        
    },
    methods : {
        /* open */
        open : function(work_data, callback) {
            this.set_data(work_data);
            this.callback = callback;
            this.is_open = true;
        },

        get_mission_type(mission_type) {
            let text = 'mission_type_'+mission_type
            if(this.$c.hasProperty(this.i18n, text)=== true) {
                return this.i18n[text];
            }
            return '';
        },
        get_mission_type_color(mission_type) {
            if(this.$c.hasProperty(mission_js.mission_type_color, mission_type)=== true) {
                return mission_js.mission_type_color[mission_type];
            }
            return '';
        },
        get_target_type(target_type) {
            let text = 'target_type_'+target_type;
            if(this.$c.hasProperty(this.i18n, text)=== true) {
                return this.i18n[text];
            }
            return '';
        },
        get_target_type_color(target_type) {
            if(this.$c.hasProperty(mission_js.target_type_color, target_type)=== true) {
                return mission_js.target_type_color[target_type];
            }
            return '';
        },

        /* set */
        set_data(work_data) {
            this.user_id = work_data.user_id;
            this.class_id = work_data.class_id;
            this.class_inst_id = work_data.class_inst_id;
            this.class_work_id = work_data.class_work_id;
            this.form.status_code = work_data.status_code;
            this.form.submit_date = work_data.submit_date;
            this.class_mission_list = work_data.class_mission_list;
            this.mission_list = work_data.mission_list;
            //지시항목 숨기기
            this.class_mission_list.forEach(m => {
                m.is_view_direction = false;
            })
            this.mission_list.forEach(m => {
                m.is_view_direction = false;
            })

            //반미션 결과 추가
            for(let i=0; i<this.class_mission_list.length; i++) {
                let m = this.class_mission_list[i];
                if(i<work_data.class_mission_result_list.length) {
                    m.result = work_data.class_mission_result_list[i];
                } else {
                    m.result = {
                        is_check : 0,
                        check_date : '',
                        is_pass : 0,
                        feedback : '',
                    };
                }
            }
            //개별미션 결과 추가
            for(let i=0; i<this.mission_list.length; i++) {
                let m = this.mission_list[i];
                if(i<work_data.student_mission_result_list.length) {
                    m.result = work_data.student_mission_result_list[i];
                } else {
                    m.result = {
                        is_check : 0,
                        check_date : '',
                        is_pass : 0,
                        feedback : '',
                    };
                }
            }
            //form에러 초기화
            this.$c.form.clear_form(this.form_error);
        },

        /* click */
        change_pass(result) {
            result.is_check = result.is_pass;
        },

        /* handle */
        save_data() {
            
            let vm = this;
            let url = `/api/admin/app/aca_erp/students/${this.user_id}/classes/class_insts/${this.class_inst_id}/works/${this.class_work_id}/result`;
            let class_mission_result = [];
            let student_mission_result = [];
            vm.class_mission_list.forEach(m=>{
                class_mission_result.push(m.result);
            });
            vm.mission_list.forEach(m=>{
                student_mission_result.push(m.result);
            });
            let form_data = {
                class_mission_result : JSON.stringify(class_mission_result),
                student_mission_result : JSON.stringify(student_mission_result),
                status_code : vm.form.status_code,
                submit_date : vm.form.submit_date,
            };
            this.$c.form.clear_form(this.form_error);
            this.$c.io.put(url, form_data).then(function(response) {
                let data = response.data;
                if(data.success) {
                    vm.is_open = false;
                    if(vm.callback) { vm.callback(); }
                } else {
                    if(data.error) {
                        for(let key in vm.form_error) {
                            vm.form_error[key] = data.error[key];
                        }
                    }
                    if(data.message) {
                        vm.$c.response_notify(0, data.message);
                    }
                }
            });
        },

    },
    
    beforeMount : function() {
    },
}
</script>


<style scope>
.mission_result .index {
    width:30px;
}
.mission_result .mission_type {
    width:60px;
    
}
.mission_result .button {
    text-align: right;
    width:140px;
}
.mission_result .feedback .q-field__control {
    height:20px;
}
.mission_result .feedback .q-field__native {
    margin:0;
    padding:0;
    height:20px;
}

.submit_date .com-input-field > .q-field__bottom {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
}
</style>