<template>
    <div>
        <com-login ></com-login>
        <com-loading></com-loading>
    </div>
</template>

<script>
import comLogin from './comLogin.vue'
import comLoading from '@/stories/comLoading/comLoading.vue'
import $app from '@/app/app.js'

export default {
    name: 'App',
    components: {
        comLogin,
        comLoading,
    },
    data() {
        return {
        }
    },
    methods : {
    },
    
    created() {
        this.$c.$root = this;
    },
    beforeMount() {
        let vm = this;
        //스토어 데이터 추가
        $app.load_language_list(vm);
        $app.load_currency_list(vm);
        //학원정보
        $app.load_center_info(vm);
    },
}
</script>

