import axios from 'axios';

const $app = (function() {
    var self = {
        load_language_list(vm) {
            vm.$store.state.language_list = [];
            vm.$store.dispatch('loading', {name:'국제화언어', loading:true});
            vm.$c.io.get('/api/system/localization/languages', {}).then(function(response){
                let data = response.data;
                if(data.success) {
                    vm.$store.state.language_list = data.language_list;
                    vm.$store.state.language_id = data.language_id;
                    vm.$store.state.language_list.forEach(x=>{
                        if(x.language_id==data.language_id) {
                            vm.$store.state.datetime_format = x.datetime_format;
                            vm.$store.state.date_format = x.date_format;
                            vm.$store.state.time_format = x.time_format;
                        }
                    });
                }
                vm.$store.dispatch('loading', {name:'국제화언어', loading:false});
            });
        },
        load_currency_list(vm) {
            vm.$store.state.currency_list = [];
            vm.$store.dispatch('loading', {name:'국제화화폐', loading:true});
            vm.$c.io.get('/api/system/localization/currencies', {}).then(function(response){
                let data = response.data;
                if(data.success) {
                    vm.$store.state.currency_list = data.currency_list;
                    vm.$store.state.currency_id = data.currency_id;
                    vm.$store.state.currency_list.forEach(x=>{
                        if(x.currency_id==data.currency_id) {
                            vm.$store.state.currency_value = x.currency_value;
                            vm.$store.state.currency_symbol_left = x.currency_symbol_left;
                            vm.$store.state.currency_symbol_right = x.currency_symbol_right;
                            vm.$store.state.decimal_place = x.decimal_place;
                        }
                    });
                }
                vm.$store.dispatch('loading', {name:'국제화화폐', loading:false});
            });
        },
        load_i18n_data(vm, url_list) {
            vm.$store.state.i18n = {};
            vm.$store.dispatch('loading', {name:'국제화data', loading:true});
            let requests = [];
            url_list.forEach(url=>{
                requests.push(vm.$c.io.get('/api/system/localization/languages/cfg', {url:url}));
            });
            vm.$store.state.i18n = {};
            axios.all(requests).then( axios.spread((...responses)=>{
                responses.forEach(res=>{
                    let data = res.data;
                    for(let key in data.i18n) {
                        vm.$store.state.i18n[key] = data.i18n[key];
                    }
                });
                vm.$store.dispatch('loading', {name:'국제화data', loading:false});
            }));
        },

        load_admin_info(vm) {
            vm.$store.dispatch('loading', {name:'관리자정보', loading:true});
            vm.$store.state.permission = {};
            let url_info = `/api/account/admin/info`;
            let url_permission = `/api/admin/app/aca_erp/settings/permissions/2`;
            let url_permission_admin = `/api/admin/app/aca_erp/settings/permission_admins/me`;
            axios.all([
                vm.$c.io.get(url_info, {}),
                vm.$c.io.get(url_permission, {}),
                vm.$c.io.get(url_permission_admin, {}),
            ]).then( axios.spread((res1, res2, res3)=>{
                let data1 = res1.data;
                if(data1.success) {
                    vm.$store.state.admin_id = data1.admin_prof.admin_id;
                    vm.$store.state.admin.admin_id = data1.admin_prof.admin_id;
                    vm.$store.state.admin.admin_group_id = data1.admin_prof.admin_group_id;
                    vm.$store.state.admin.a_name = data1.admin_prof.a_name;
                    vm.$store.state.admin.admin_name = data1.admin_prof.admin_name;
                }

                let data2 = res2.data;
                vm.$store.state.permission = {};
                let permission_parent = {};
                if(data2.success) {
                    data2.permission_list.forEach(x => {
                        if(vm.$c.hasProperty(vm.$store.state.permission, x.group_name)===false) {
                            vm.$store.state.permission[x.group_name] = {
                                view : 0, write : 0,
                                page : {},
                            }
                        }
                        let page = vm.$store.state.permission[x.group_name].page;
                        page[x.permission_name] = { 'view' : x.view, 'write' : x.write, 'title':x.title, };
                        permission_parent[x.permission_name] = x.group_name;
                    });
                }
                let data3 = res3.data;
                function set_permission(value) {
                    for(let key in vm.$store.state.permission) {
                        let group = vm.$store.state.permission[key];
                        group.view = value;
                        group.write = value;
                        for(let page_key in group.page) {
                            group.page[page_key].view = value;
                            group.page[page_key].write = value;
                        }
                    }
                }
                if(data3.success) {
                    //권한 초기화
                    if(data3.permission_list.length>0) {
                        //개별권한이 설정되어 있으므로, permission 초기화 후 개별권한 설정함.
                        set_permission(0);
                    }
                    //개별권한으로 설정
                    data3.permission_list.forEach(x=>{
                        if(vm.$c.hasProperty(permission_parent, x.permission_name)===true) {
                            let key = permission_parent[x.permission_name];
                            let page_key = x.permission_name;
                            vm.$store.state.permission[key].page[page_key].view = x.view;
                            vm.$store.state.permission[key].page[page_key].write = x.write;
                        }
                    });
                }
                //관리자그룹이면 모든 권한을 설정함.
                if(vm.$store.state.admin.admin_group_id===1) {
                    set_permission(1);
                } else {
                    //화면보기 권한 설정 --> 하위의 page의 보기 권한중 하나라도 있어야함.
                    for(let key in vm.$store.state.permission) {
                        let group = vm.$store.state.permission[key];
                        //홈화면은 무조건 보기 설정됨
                        if(key==='staff/home') { 
                            group.view = 1;
                            group.write = 1;
                            continue;
                        }
                        group.view = 0;
                        group.write = 0;
                        for(let page_key in group.page) {
                            if(group.page[page_key].view===1) {
                                group.view = 1;
                            }
                            if(group.page[page_key].write===1) {
                                group.write = 1;
                            }
                        }
                    }
                }
                vm.$store.dispatch('loading', {name:'관리자정보', loading:false});
            } ));

        },
        
        load_teacher_list(vm) {
            vm.$store.state.teacher_list = [];
            vm.$store.dispatch('loading', {name:'선생님목록', loading:true});
            let form_data = { name: '' };
            vm.$c.io.get('/api/account/admin/admins/auto_name', form_data).then(function(response){
                let data = response.data;
                if(data.success) {
                    data.results.sort(function(a,b){
                        if(a.name>b.name) return 1;
                        if(a.name<b.name) return -1;
                        return 0;
                    });
                    data.results.forEach(x=>{
                        let name = x.name;
                        let name_type = 0;
                        if(!name) { 
                            name = x.admin_name; 
                            name_type = 1;
                        }
                        vm.$store.state.teacher_list.push({label:name, value:x.value, 
                            admin_id:x.value, admin_name:x.admin_name, name_type:name_type });
                    });
                }
                vm.$store.dispatch('loading', {name:'선생님목록', loading:false});
            });
        },
        
        load_ref_class_type(vm) {
            vm.$store.dispatch('loading', {name:'반형태', loading:true});
            vm.$store.state.class_type_list = [];
            let filter = {};
            if(!vm.include_close) {
                filter['is_alive'] = {"filterType": "number","type": "=","filter": 1};
            }
            let form_data = {
                page : 1,
                limit : 0,
                filter : JSON.stringify(filter),
                sort : '[]',
                tzoffset : vm.$c.tzoffset,
            };
            vm.$c.io.get('/api/admin/app/aca_erp/classes/class_types', form_data).then( function(response) {
                var data = response.data;
                if(data['success']) {
                    data.class_type_list.forEach(x=>{
                        vm.$store.state.class_type_list.push({
                            label:x.class_type, 
                            value:x.class_type_id,
                            class_type_id: x.class_type_id,
                        });
                    });
                } 
                vm.$store.dispatch('loading', {name:'반형태', loading:false});
            });
        },
        
        load_textbook_list(vm) {
            vm.$store.state.textbook_list = [];
            vm.$store.dispatch('loading', {name:'교재목록', loading:true});
            let form_data = { 'name': '' };
            vm.$c.io.get('/api/admin/app/aca_erp/classes/textbooks', form_data).then(function(response){
                let data = response.data;
                if(data.success) {
                    vm.$store.state.textbook_list = data.textbook_list;
                    vm.$store.state.textbook_list.forEach(x => {
                        x.label = x.textbook_name;
                        x.value = x.textbook_id;
                    });
                    vm.$store.state.textbook_list.sort(function(a,b){
                        if(a.label>b.label) return 1;
                        if(a.label<b.label) return -1;
                        return 0;
                    });
                }
                vm.$store.dispatch('loading', {name:'교재목록', loading:false});
            });
        },
        
        load_setting_list(vm, keyword1, callback) {
            let url = `/api/admin/app/aca_erp/settings/setting/${keyword1}`;
            vm.$c.io.get(url, {}).then( function(response) {
                var data = response.data;
                if(data['success']) {
                    data.setting_list.sort((a,b)=>{
                        return a.sort_order-b.sort_order;
                    });
                } 
                if(callback) {
                    callback(data);
                }
            });
        },

        //요일목록
        load_weekday_list(vm) {
            vm.$store.dispatch('loading', {name:'요일목록', loading:true});
            vm.$store.state.weekday = {};
            self.load_setting_list(vm, 'weekday', function(data){
                if(data.success) {
                    data.setting_list.forEach(x=>{
                        vm.$store.state.weekday[x.keyword2] = x.value;
                    });
                }
                vm.$store.dispatch('loading', {name:'요일목록', loading:false});
            }); 
        },
        //입반사유
        load_incoming_path_list(vm) {
            vm.$store.dispatch('loading', {name:'입반사유', loading:true});
            vm.$store.state.incoming_path_list = [];
            self.load_setting_list(vm, 'incoming_path', function(data){
                if(data.success) {
                    data.setting_list.forEach(x=>{
                        vm.$store.state.incoming_path_list.push( x.keyword2 );
                    });
                }
                vm.$store.dispatch('loading', {name:'입반사유', loading:false});
            }); 
        },
        //퇴반사유
        load_close_reason_type_list(vm) {
            vm.$store.state.close_reason_type_list = [];
            vm.$store.dispatch('loading', {name:'퇴반사유', loading:true});
            self.load_setting_list(vm, 'close_reason_type', function(data){
                if(data.success) {
                    data.setting_list.forEach(x=>{
                        vm.$store.state.close_reason_type_list.push( x.keyword2 );
                    });
                }
                vm.$store.dispatch('loading', {name:'퇴반사유', loading:false});
            }); 
        },
        //할인종류
        load_discount_list(vm) {
            vm.$store.state.discount_list = [];
            vm.$store.dispatch('loading', {name:'할인종류', loading:true});
            self.load_setting_list(vm, 'discount', function(data){
                if(data.success) {
                    let idx = 1;
                    data.setting_list.forEach(x=>{
                        x.label = x.keyword2;
                        x.discount_name = x.label;
                        x.discount_type = x.value_type;
                        x.discount = x.value;
                        x.value = idx; //select에서 value값을 키값으로 사용하기때문에 교체함
                        idx += 1;
                        vm.$store.state.discount_list.push( x );
                    });
                }
                vm.$store.dispatch('loading', {name:'할인종류', loading:false});
            });
        },
        //학생상태종류
        load_student_status_list(vm) {
            vm.$store.state.student_status_list = [];
            vm.$store.dispatch('loading', {name:'학생상태종류', loading:true});
            self.load_setting_list(vm, 'student_status', function(data){
                if(data.success) {
                    data.setting_list.forEach(x=>{
                        x.label = x.value;
                        x.student_status = x.keyword2;
                        vm.$store.state.student_status_list.push( x );
                    });
                }
                vm.$store.dispatch('loading', {name:'학생상태종류', loading:false});
            }); 
        },
        //학생출결종류
        load_attendance_status_list(vm) {
            vm.$store.state.attendance_status_list = [];
            vm.$store.dispatch('loading', {name:'학생출결종류', loading:true});
            self.load_setting_list(vm, 'attendance_status', function(data){
                if(data.success) {
                    data.setting_list.forEach(x=>{
                        x.label = x.value;
                        x.attendance_status = x.keyword2;
                        vm.$store.state.attendance_status_list.push( x );
                    });
                }
                vm.$store.dispatch('loading', {name:'학생출결종류', loading:false});
            }); 
        },
        //현금종류
        load_cash_type_list(vm) {
            vm.$store.state.cash_type_list = [];
            vm.$store.dispatch('loading', {name:'현금종류', loading:true});
            self.load_setting_list(vm, 'cash_type', function(data){
                if(data.success) {
                    data.setting_list.forEach(x=>{
                        x.label = x.value;
                        x.cash_type = x.keyword2;
                        vm.$store.state.cash_type_list.push( x );
                    });
                }
                vm.$store.dispatch('loading', {name:'현금종류', loading:false});
            }); 
        },
        //카드종류
        load_card_type_list(vm) {
            vm.$store.state.card_type_list = [];
            vm.$store.dispatch('loading', {name:'카드종류', loading:true});
            self.load_setting_list(vm, 'card_type', function(data){
                if(data.success) {
                    data.setting_list.forEach(x=>{
                        x.label = x.value;
                        x.card_type = x.keyword2;
                        vm.$store.state.card_type_list.push( x );
                    });
                }
                vm.$store.dispatch('loading', {name:'카드종류', loading:false});
            }); 
        },
        //결제승인구분
        load_payment_name_list(vm) {
            vm.$store.state.payment_name_list = [];
            vm.$store.dispatch('loading', {name:'결제승인구분', loading:true});
            self.load_setting_list(vm, 'payment_name', function(data){
                if(data.success) {
                    data.setting_list.forEach(x=>{
                        x.label = x.value;
                        x.payment_name = x.keyword2;
                        vm.$store.state.payment_name_list.push( x );
                    });
                }
                vm.$store.dispatch('loading', {name:'결제승인구분', loading:false});
            }); 
        },
        //출결상태
        load_attend_status_list(vm) {
            vm.$store.dispatch('loading', {name:'출결상태', loading:true});
            vm.$store.state.attend_status_list = [];
            self.load_setting_list(vm, 'attend_status', function(data){
                if(data.success) {
                    data.setting_list.forEach(x=>{
                        x.label = x.value;
                        x.payment_name = x.keyword2;
                        vm.$store.state.attend_status_list.push( x );
                    });
                }
                vm.$store.dispatch('loading', {name:'출결상태', loading:false});
            }); 
        },
        //상담종류
        load_counsel_type_list(vm) {
            vm.$store.dispatch('loading', {name:'상담종류', loading:true});
            vm.$store.state.counsel_type_list = [];
            self.load_setting_list(vm, 'counsel_type', function(data){
                if(data.success) {
                    data.setting_list.forEach(x=>{
                        x.label = x.value;
                        x.counsel_type = x.keyword2;
                        vm.$store.state.counsel_type_list.push( x );
                    });
                }
                vm.$store.dispatch('loading', {name:'상담종류', loading:false});
            }); 
        },
        //보강상태종류
        load_makeup_class_status_list(vm) {
            vm.$store.dispatch('loading', {name:'보강상태종류', loading:true});
            vm.$store.state.makeup_class_status_list = [];
            self.load_setting_list(vm, 'makeup_class_status', function(data){
                if(data.success) {
                    data.setting_list.forEach(x=>{
                        x.label = x.value;
                        x.makeup_class_status = x.keyword2;
                        vm.$store.state.makeup_class_status_list.push( x );
                    });
                }
                vm.$store.dispatch('loading', {name:'보강상태종류', loading:false});
            }); 
        },
        //수강료종류
        load_billing_type_list(vm) {
            vm.$store.dispatch('loading', {name:'수강료종류', loading:true});
            vm.$store.state.billing_type_list = [];
            self.load_setting_list(vm, 'billing_type', function(data){
                if(data.success) {
                    data.setting_list.forEach(x=>{
                        x.label = x.value;
                        x.billing_type = x.keyword2;
                        vm.$store.state.billing_type_list.push( x );
                    });
                }
                vm.$store.dispatch('loading', {name:'수강료종류', loading:false});
            }); 
        },
        //납입형태
        load_pay_type_list(vm) {
            vm.$store.dispatch('loading', {name:'납입형태', loading:true});
            vm.$store.state.pay_type_list = [];
            self.load_setting_list(vm, 'pay_type', function(data){
                if(data.success) {
                    data.setting_list.forEach(x=>{
                        x.pay_type = x.keyword2;
                        x.label = x.value;
                        x.value = x.keyword2;
                    });
                }
                vm.$store.state.pay_type_list = data.setting_list;
                vm.$store.dispatch('loading', {name:'납입형태', loading:false});
            });
        },
        //수납문자메시지
        load_pay_message_list(vm) {
            vm.$store.dispatch('loading', {name:'수납문자메시지', loading:true});
            vm.$store.state.pay_message_list = [];
            self.load_setting_list(vm, 'pay_message', function(data){
                if(data.success) {
                    data.setting_list.forEach(x=>{
                        x.label = x.value;
                        x.message_type = x.keyword2;
                        vm.$store.state.pay_message_list.push( x );
                    });
                }
                vm.$store.dispatch('loading', {name:'수납문자메시지', loading:false});
            });
        },

        //시험종류
        load_test_type_list(vm) {
            vm.$store.dispatch('loading', {name:'시험종류', loading:true});
            vm.$store.state.test_type_list = [];
            self.load_setting_list(vm, 'test_type', function(data){
                if(data.success) {
                    data.setting_list.forEach(x=>{
                        x.label = x.value;
                        x.test_type = x.keyword2;
                        vm.$store.state.test_type_list.push( x );
                    });
                }
                vm.$store.dispatch('loading', {name:'시험종류', loading:false});
            }); 
        },
        //과제평가종류
        load_homework_result_type_list(vm) {
            vm.$store.dispatch('loading', {name:'과제평가종류', loading:true});
            vm.$store.state.homework_result_type_list = [];
            self.load_setting_list(vm, 'homework_result_type', function(data){
                if(data.success) {
                    data.setting_list.forEach(x=>{
                        x.label = x.value;
                        x.homework_result_type = x.keyword2;
                        vm.$store.state.homework_result_type_list.push( x );
                    });
                }
                vm.$store.dispatch('loading', {name:'과제평가종류', loading:false});
            }); 
        },
        //미션평가종류
        load_class_work_status_code_list(vm) {
            vm.$store.dispatch('loading', {name:'미션평가종류', loading:true});
            vm.$store.state.class_work_status_code_list = [];
            self.load_setting_list(vm, 'class_work_status_code', function(data){
                if(data.success) {
                    data.setting_list.forEach(x=>{
                        x.label = x.value;
                        x.class_work_status_code = x.keyword2;
                        vm.$store.state.class_work_status_code_list.push( x );
                    });
                }
                vm.$store.dispatch('loading', {name:'미션평가종류', loading:false});
            }); 
        },
        //수업후기메시지
        load_class_report_message_list(vm) {
            vm.$store.dispatch('loading', {name:'수업후기메시지', loading:true});
            vm.$store.state.class_report_message_list = [];
            self.load_setting_list(vm, 'class_report_message', function(data){
                if(data.success) {
                    data.setting_list.forEach(x=>{
                        x.label = x.value;
                        x.class_report = x.keyword2;
                        vm.$store.state.class_report_message_list.push( x );
                    });
                }
                vm.$store.dispatch('loading', {name:'수업후기메시지', loading:false});
            }); 
        },
        //일지항목
        load_daily_report_items_list(vm) {
            vm.$store.dispatch('loading', {name:'일지항목', loading:true});
            vm.$store.state.daily_report_items = [];
            self.load_setting_list(vm, 'daily_report_items', function(data){
                if(data.success) {
                    data.setting_list.forEach(x=>{
                        x.label = x.value;
                        x.daily_report = x.keyword2;
                        vm.$store.state.daily_report_items.push( x );
                    });
                }
                vm.$store.dispatch('loading', {name:'일지항목', loading:false});
            }); 
        },

        //초중고목록
        load_school_level_list(vm) {
            vm.$store.dispatch('loading', {name:'초중고목록', loading:true});
            vm.$store.state.school_level_list = [];
            self.load_setting_list(vm, 'school_level', function(data){
                if(data.success) {
                    data.setting_list.forEach(x=>{
                        x.school_level = x.keyword2;
                        x.label = x.value;
                        x.value = x.keyword2;
                    });
                }
                vm.$store.state.school_level_list = data.setting_list;
                vm.$store.dispatch('loading', {name:'초중고목록', loading:false});
            }); 
        },
        //학년단위목록
        load_school_grade_list(vm) {
            vm.$store.dispatch('loading', {name:'학년단위목록', loading:true});
            vm.$store.state.school_grade_dict = {};
            self.load_setting_list(vm, 'school_grade', function(data){
                if(data.success) {
                    data.setting_list.forEach(x=>{
                        let grade_list = []
                        try {
                            grade_list = JSON.parse(x.value);
                        } catch(e) {
                            grade_list = [];
                        }
                        vm.$store.state.school_grade_dict[x.keyword2] = grade_list;
                    });
                }
                vm.$store.dispatch('loading', {name:'학년단위목록', loading:false});
            }); 
        },

        
        //학원정보
        load_center(vm) {
            vm.$store.dispatch('loading', {name:'학원정보', loading:true});
            vm.$store.state.center_info = {};
            vm.$store.state.center_api = {};
            self.load_setting_list(vm, 'center', function(data){
                if(data.success) {
                    data.setting_list.forEach(x=>{
                        let data = {};
                        try {
                            data = JSON.parse(x.value);
                        } catch(e) {
                            data = {};
                        }
                        
                        if(x.keyword2=='info') {
                            vm.$store.state.center_info = data;
                        } else if(x.keyword2=='api') {
                            vm.$store.state.center_api = data;
                        }
                    });
                }
                vm.$store.dispatch('loading', {name:'학원정보', loading:false});
            }); 
        },
        //학원정보
        load_center_info(vm) {
            vm.$store.dispatch('loading', {name:'학원정보', loading:true});
            vm.$store.state.center_info = {};
            let url = `/api/app/aca_erp/center`;
            vm.$c.io.get(url, {}).then( function(response){
                var data = response.data;
                if(data['success']) {
                    //console.log(data);
                    vm.$store.state.center_info = data.center;
                } 
                vm.$store.dispatch('loading', {name:'학원정보', loading:false});
            });
        },
        
    };
    return self;
})();


export default $app;
