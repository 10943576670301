<template>
<div class="grade_form">
    <div class="mb3">
        <span class="border py3 pr3">
                    <q-checkbox label="전체" v-model="select_all" @update:model-value="change_school_level()" />
        </span> 
        <q-chip class="bg-white"> 이거나 </q-chip>
    </div>
    <div class="mb3"> 
        <span class="border py3 pr3">
            <q-checkbox label="초" val="primary" v-model="select_level" @update:model-value="change_school_level('primary')" />
            <q-checkbox label="중" val="middle" v-model="select_level" @update:model-value="change_school_level('middle')" />
            <q-checkbox label="고" val="high" v-model="select_level" @update:model-value="change_school_level('high')" />
        </span> 
        <q-chip class="bg-white"> 이거나 </q-chip>
    </div>
    <div class="mb3"> 
        <div class="border pr3">
            <div>
                <q-radio label="초" val="primary" v-model="select_level_radio" @update:model-value="change_school_grade" />
                <q-checkbox :label="'초'+grade" :val="grade" 
                            v-model="select_grade.primary" @update:model-value="change_school_grade('primary')" 
                            v-for="grade in school_grade_dict.primary" 
                            :key="grade" />
            </div>
            <div>
                <q-radio label="중" val="middle" v-model="select_level_radio" @update:model-value="change_school_grade" />
                <q-checkbox :label="'중'+grade" :val="grade" 
                            v-model="select_grade.middle" @update:model-value="change_school_grade('middle')" 
                            v-for="grade in school_grade_dict.middle" 
                            :key="grade" />
            </div>
            <div>
                <q-radio label="고" val="high" v-model="select_level_radio" @update:model-value="change_school_grade" />
                <q-checkbox :label="'고'+grade" :val="grade" 
                            v-model="select_grade.high" @update:model-value="change_school_grade('high')" 
                            v-for="grade in school_grade_dict.high"
                            :key="grade" />
            </div>
        </div> 
    </div>
</div>
</template>


<script>
export default {
    data : function() {
        return {
            select_all : true,
            select_level : [],
            select_level_radio : '',
            select_grade : {
                primary : [],
                middle : [],
                high : [],
            },
            form : {
                school_level : [],
                school_grade : [],
            },
        }
    },
    computed : {
        school_level_list() {
            return this.$store.state.school_level_list;
        },
        school_grade_dict() {
            return this.$store.state.school_grade_dict;
        },
    },
    methods : {
        change_school_level(level) {
            this.select_level_radio = '';
            this.select_grade.primary = [];
            this.select_grade.middle = [];
            this.select_grade.high = [];
            
            if(!level) {
                //전체선택버튼
                this.select_level = [];
                //form 데이터 세팅
                this.form.school_level = [];
                this.form.school_grade = [];
            } else {
                //초중고 checkbox 선택
                this.select_all = this.select_level.length==0;
                //form 데이터 세팅
                this.form.school_level = this.select_level;
                this.form.school_grade = [];
            }
            this.$emit('selected_grade', {school_level:this.form.school_level, school_grade:this.form.school_grade});
        },
        change_school_grade(level) {
            this.select_all = false;
            this.select_level = [];
            if(!level) {
                //초중고 radio 선택
                this.select_grade.primary = [];
                this.select_grade.middle = [];
                this.select_grade.high = [];
                //form 데이터 세팅
                this.form.school_level = [this.select_level_radio];
                this.form.school_grade = [];
            } else {
                //초중고 학년 선택
                this.select_level_radio = level;
                for(let key in this.select_grade) {
                    if(key!=level) {
                        this.select_grade[key]= [];
                    }
                }
                //form 데이터 세팅
                this.form.school_level = [level];
                this.form.school_grade = this.select_grade[level];
            }
            this.$emit('selected_grade', {school_level:this.form.school_level, school_grade:this.form.school_grade});
        },
    },
    beforeMount : function() {
    },
    mounted : function() {
    },
}
</script>