<template>
    <div>
        <q-dialog persistent class="dialog_webcam" v-model="is_open">
            <q-card class="q-px-sm q-pb-sm" style="width:600px; max-width:80vw;">
                <q-card-section>
                    <div class="text-center text-h6">웹캠으로 사진등록</div>
                </q-card-section>
                <q-card-section class="q-py-none">
                            <table>
                            <tbody>
                            <tr>
                                <td>
                                    <div class="container">
                                        <video autoplay="true" class="video_element" ref="video_element"></video>
                                    </div>
                                </td>
                                <td style="padding:8px 0;" class="text-right">
                                        <div class="text-left">
                                            * 사진등록방법 *<br/>
                                            1) 웹캠에서 '캡쳐'버튼을 클릭<br/>
                                            2) '사진등록'버튼으로 저장<br/>
                                        </div>
                                        <div class="border my2 ">
                                            <canvas class="capture" ref="canvas"></canvas>
                                        </div>
                                        <div>
                                            <q-btn color="primary" label="캡처" outline @click="take_photo" ></q-btn>
                                        </div>
                                </td>
                            </tr>
                            </tbody>
                            </table>
                </q-card-section>
                <q-card-actions align="right" class="q-px-md">
                    <q-btn color="grey" label="취소" outline v-close-popup></q-btn>
                    <q-btn @click="handle_submit" color="primary" label="사진등록" outline ></q-btn>
                </q-card-actions>
            </q-card>
        </q-dialog>
    </div>
</template>

<script>
export default {
    data : function() {
        return {
            is_open : false,
            callback : null,
            user_id : 0,
            folder : '',
            form: {
                date_started : '',
                tzoffset : this.$c.tzoffset,
            },
        };
    },
    computed: {
        datetime_format : function() {
            return this.$store.state.datetime_format;
        },
        date_format : function() {
            return this.$store.state.date_format;
        },
        time_format : function() {
            return this.$store.state.time_format;
        },
    },
    methods : {
        /* open */
        open : function(user_id, folder, callback) {
            let vm = this;
            this.user_id = user_id;
            this.folder = folder;
            this.callback = callback;
            this.is_open = true;
            this.$nextTick(function() {
                vm.start_video();
            });
        },
        
        /* video */
        start_video() {
            let video = this.$refs.video_element;
            if (navigator.mediaDevices.getUserMedia) {
                navigator.mediaDevices.getUserMedia({ video: true }).then(function (stream) {
                    video.srcObject = stream;
                }).catch(function (error) {
                    console.log("Something went wrong!", error);
                });
            }
        },
        
        take_photo() {
            let video = this.$refs.video_element;
            let canvas = this.$refs.canvas;
            //let video_w = video.videoWidth;
            //let video_h = video.videoHeight;
            let canvas_w = 180;
            let canvas_h = 137;
            canvas.width = canvas_w;
            canvas.height = canvas_h;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(video, 0, 0, canvas_w, canvas_h);
        },
        
        /* handle */
        handle_submit() {
            let canvas = this.$refs.canvas;
            var myImg = canvas.toDataURL("image/png");
            this.uploadFile( myImg );
        },
        
        uploadFile : function( file ) {
            var vm = this;
            this.percent = 0;
            var formData = new FormData();
            var ajax = new XMLHttpRequest();
            formData.append("upfile", file);
            formData.append("folder", this.folder);
            
            ajax.onreadystatechange = function () {
                if (ajax.status) {
                    if (ajax.status == 200 && (ajax.readyState == 4)){
                        var data = JSON.parse(ajax.responseText);
                        if( data.success) {
                            vm.is_open = false;
                            if( vm.callback ) {
                                vm.callback(data);
                            }
                        } else {
                            let message = '';
                            if(data.message) {
                                message = data.message;
                            }
                            if(data.error) {
                                for(var key in data.error) {
                                    if(message){ message+='\n'; }
                                    message += '-'+key+':'+data.error[key];
                                }
                            }
                            if(message) {
                                vm.$c.response_notify(0, message);
                            }
                        }
                    }
                }
            }
            ajax.upload.addEventListener("progress", function (event) {
                var percent = (event.loaded / event.total) * 100;
                vm.percent = percent;
            });
            ajax.open("POST", '/api/admin/tool/upload/capture_image', true);
            ajax.send(formData);
        },
    },
    
    beforeMount : function() {
    },
    mounted() {
    },
};
</script>

<style>
.dialog_webcam .container  {
    width: 350px;
    height: 267px;
    border: 10px #333 solid;
    display:inline-block;
}
.dialog_webcam .video_element {
    width: 330px;
    height: 247px;
    background-color: #666;
}
.dialog_webcam .capture {
    display:inline-block;
    width: 180px;
    height: 137px;
}
</style>
