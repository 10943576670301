<template>
<div class="school_list">
    <div class="mb1">
        <span class="border py3 pr3">
                    <q-radio label="전체" val="" v-model="school_level" @update:model-value="change_school_level('')" />
                    <q-radio label="초" val="primary" v-model="school_level" @update:model-value="change_school_level('primary')" />
                    <q-radio label="중" val="middle" v-model="school_level" @update:model-value="change_school_level('middle')" />
                    <q-radio label="고" val="high" v-model="school_level" @update:model-value="change_school_level('high')" />
        </span> 
        <q-chip class="bg-white"> 그리고 </q-chip>
    </div>
    <div class="mb3">
        <q-input outlined dense style="width:250px" placeholder="학교이름검색"
                 v-model="school_name" @keydown.enter.prevent="search_school_name" >
            <template v-slot:append >
                <a @click="search_school_name">
                    <q-icon name="search" />
                </a>
            </template>
        </q-input>
    </div>
    <q-table :rows="school_list" separator="cell" flat bordered dense
             :rows-per-page-options="[0]" row-key="school_id" 
             table-header-class="bg-blue-1"
             class="sticky-header-table" style="height:280px" 
             :key="key_school_list">
        <template v-slot:header>
            <tr class="bg-blue-1">
                <th class="w40px">
                    <q-checkbox dense v-model="is_selected_all" @update:model-value="change_select_all" />
                </th>
                <th class="w60px">
                    초중고
                </th>
                <th class="text-left"> 
                    학교이름
                </th>
            </tr>
        </template>
        <template v-slot:body="props">
            <tr class="pointer"
                :class="{active:props.row.is_selected}" @click="click_select(props.row)">
                <td>
                    <q-checkbox dense v-model="props.row.is_selected" @update:model-value="change_select(props.row)"/>
                </td>
                <td class="text-center v-middle"> 
                    {{props.row.school_level_name}}
                </td>
                <td class="v-middle"> 
                    {{props.row.school_name}}
                </td>
            </tr>
        </template>
        <template  v-slot:bottom>
                <q-pagination
                    v-model="pagination.page"
                    :max="pagination.total_pages"
                    size="sm"
                    input
                    @update:model-value="change_pagination"
                />
        </template>
    </q-table>
    
</div>
</template>


<script>
export default {
    data : function() {
        return {
            key_school_list : 1,
            school_level : '',
            school_name : '',
            form : {
                school_level : '',
                school_name : '',
            },
            
            school_list : [],
            pagination : {
                page: 1,
                limit : 20,
                total_pages: 0,
                total_count: 0
            },
            is_selected_all:false,
        }
    },
    computed : {
        school_level_list() {
            return this.$store.state.school_level_list;
        },
    },
    methods : {
        
        /* select */
        change_select() {
            this.check_select_all();
            this.change_selected();
        },
        change_select_all() {
            this.school_list.forEach(x => {
                x.is_selected = this.is_selected_all;
            });
            this.change_selected();
        },
        check_select_all() {
            let is_all = true;
            this.school_list.forEach(x => {
                if(x.is_selected == false) {
                    is_all = false;
                }
            });
            this.is_selected_all = is_all;
        },
        clear_selection() {
            this.school_list.forEach(x => {
                x.is_selected = false;
            });
            this.is_selected_all = false;
            this.change_selected();
        },
        
        /* change */
        change_school_level(school_level) {
            this.pagination.page = 1;
            this.form.school_level = school_level;
            this.form.school_name = '';
            this.school_name = '';
            this.load_school_list();
        },
        search_school_name() {
            this.pagination.page = 1;
            this.form.school_level = '';
            this.form.school_name = this.school_name;
            this.load_school_list();
        },
        change_pagination(page) {
            this.pagination.page = page;
            this.load_school_list();
        },
        click_select(row) {
            row.is_selected=!row.is_selected; //토글
            this.check_select_all();
            this.change_selected();
        },
        change_selected() {
            let selected = [];
            this.school_list.forEach(x => {
                if(x.is_selected) {
                    selected.push(x);
                }
            });
            this.$emit('selected_school', {school_level:this.form.school_level, school_list:selected});
        },
        
        
        /* load */
        load_school_list : function() {
            let vm = this;
            let filter = {};
            if(vm.form.school_level) {
                filter['school_level'] = {"filterType": "text","type": "=","filter": vm.form.school_level};
            }
            if(vm.form.school_name) {
                filter['school_name'] = {"filterType": "text","type": "startswith","filter": vm.form.school_name};
            }
            let form_data = {
                page : vm.pagination.page,
                limit : vm.pagination.limit,
                filter : JSON.stringify(filter),
                sort : '[]',
                tzoffset : this.$c.tzoffset,
            };
            this.$c.io.get('/api/admin/app/aca_erp/students/ref_schools', form_data).then( function(response) {
                var data = response.data;
                if(data['success']) {
                    let school_level_dict = {};
                    vm.school_level_list.forEach(x => {
                        school_level_dict[x.keyword2] = x;
                    });
                    for(let i=0; i<data['school_list'].length; i++) {
                        let p = data['school_list'][i];
                        p.school_level_name = '';
                        if( vm.$c.hasProperty(school_level_dict,p.school_level)==true) {
                            p.school_level_name = school_level_dict[p.school_level].label[0];
                        }
                    }
                    vm.school_list = data['school_list'];
                    vm.pagination.page = data.pagination.page;
                    vm.pagination.total_count = data.pagination.total_count;
                    vm.pagination.total_pages = data.pagination.total_pages;
                    //테이블 화면 갱신 + 학교리스트 갱신
                    vm.key_school_list += 1;
                } 
                vm.clear_selection();
            });
        },
    },
    beforeMount : function() {
        this.load_school_list();
    },
    mounted : function() {
    },
}
</script>