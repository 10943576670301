<template>
<div>
    <div class="com-admin">
        <div class="q-pa-md bg-grey-3 p-relative">
            <q-breadcrumbs>
                <q-breadcrumbs-el label="에러 메시지" icon="view_list" />
            </q-breadcrumbs>
        </div>
        
        <div class="q-pa-md">
            
            <q-banner inline-actions class="text-white bg-red q-py-md">
                <div class="text-subtitle1 q-mb-sm">{{i18n.error_message_title}}</div>
                {{i18n.error_no_permission}}
            </q-banner>
            
        </div>
    </div>
</div>
</template>

<script>

export default {
    components : {
    },
    data : function() {
        return {
        }
    },
    computed : {
        i18n() {
            return this.$store.state.i18n;
        }
    },
    methods: {
    },
    beforeMount() {
        console.log(this.i18n);
    },
    mounted() {
    }
}
</script>